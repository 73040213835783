// Buttons
@mixin btn {
  border-radius: 6px;
  font-family: 'Avenir-Light';
  cursor: pointer;
  height: 43px;
  line-height: 43px;
  display: block;
  border: unset;
  -webkit-appearance: unset !important;
  @include transition(all, 0.2s, ease-in);

  i {
    font-size: 14px;
    @include transition(all, 0.3s, ease);
  }
}
