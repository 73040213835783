.dashboard {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.main-content {
  margin-left: 200px;
  margin-top: 60px;
  padding-bottom: 15px;
  height: 100%;
  position: relative;
  flex-direction: column;
  @include transition(all, 0.2s, ease-in-out);

  &.collapsed {
    margin-left: 80px;

    > .faq {
      .sidebar {
        margin-left: 80px;
      }
    }

    @include bp-mobile {
      margin-left: 0;
    }
  }

  @include bp-mobile {
    width: 100%;
    margin-left: 0;
    height: initial;
  }

  > div {
    margin-top: 15px;
    animation: fadeIn 0.4s linear forwards;
    @include transition(all, 0.2s, ease-in-out);

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &.faq {
      height: 100%;
      margin-top: 0;
    }

    &.perfil {
      .billing {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .box-styles {
    padding: 15px;
  }
}
