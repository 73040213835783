.modal-user-want-to-reset-eq {
  @include bp-mobile {
    width: calc(100% - 20px) !important;
  }

  .ant-modal-title {
    p {
      font-size: 28px;
      line-height: 1;
    }
  }

  .ant-modal-footer {
    border-top: unset;
    padding: 0 24px 16px;

    button {
      line-height: 1;
      max-width: 320px;

      &:after {
        content: unset;
      }

      &:last-of-type {
        margin: 20px auto 0;
        background-color: lighten($gestorando, 55%);
        color: $gestorando !important;
        border-color: $gestorando;
      }
    }
  }
}
