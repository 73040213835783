.important-alert {
  padding-left: 86px !important;

  .anticon {
    font-size: 48px !important;
    top: calc(50% - 24px) !important;
    bottom: 0 !important;
  }

  &--with-button {
    .anticon {
      top: calc(50% - 24px - 34px) !important;
    }
  }

  @include bp-mobile {
    padding-left: 15px !important;

    .anticon {
      display: block !important;
      position: relative !important;
      top: unset !important;
      left: 0 !important;
      bottom: unset !important;
      line-height: 0 !important;
      font-size: 48px;
      margin-bottom: 15px;
    }
  }
}
