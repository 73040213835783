.content {
  margin: 30px 0 0;

  button {
    color: $gestorando;
    text-decoration: underline;
    display: block;
    margin: auto;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .table-categories {
    margin-top: 15px;
    display: none;
    padding: 15px;
    background-color: $white;
    border-radius: 7px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

    .cyan-category {
      background-color: #00bcd4;
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 15px;
      width: 20px;
      height: 20px;
      border-radius: 15px;

      &-text {
        display: inline-block;
        margin-top: 0;
        font-size: 14px;
      }
    }

    table {
      width: 100%;

      thead {
        tr {
          th {
            padding: 4px;
            border: none;
            font-family: 'Avenir-Heavy';
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: $gray;
          }

          &.active-category {
            td {
              border: 2px solid #00bcd4 !important;
            }
          }

          td {
            padding: 6px !important;
            border: 1px solid #ccc !important;
          }
        }
      }
    }
  }
}
