.content {
  > div {
    animation: fadeIn 0.4s linear forwards;
    @include transition(all, 0.2s, ease-in-out);

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    // Plan
    @import "plan";

    // Account
    @import "account";

    // Payment
    @import "payment";
    //PlansProducts
    @import "plans_products";

    // Billing
    @import "billing";
  }
}
