.loader.sk-circle {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  cursor: wait;
  background-color: rgba(255, 255, 255, 0.65);

  > div {
    margin: auto auto;
    width: 140px;
    height: 140px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: transparent;
    text-align: center;

    img {
      width: 65%;
      height: 100%;
      margin: auto;
      display: block;
      position: relative;
    }

    .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: darken(#dfe3e8, 20);
        border-radius: 100%;
        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      }
    }

    .sk-circle2 {
      @include transform(rotate(30deg));

      &:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
    }

    .sk-circle3 {
      @include transform(rotate(60deg));

      &:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }

    .sk-circle4 {
      @include transform(rotate(90deg));

      &:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
    }

    .sk-circle5 {
      @include transform(rotate(120deg));

      &:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }

    .sk-circle6 {
      @include transform(rotate(150deg));

      &:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }
    }

    .sk-circle7 {
      @include transform(rotate(180deg));

      &:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }
    }

    .sk-circle8 {
      @include transform(rotate(210deg));

      &:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
    }

    .sk-circle9 {
      @include transform(rotate(240deg));

      &:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
    }

    .sk-circle10 {
      @include transform(rotate(270deg));

      &:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
    }

    .sk-circle11 {
      @include transform(rotate(300deg));

      &:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }
    }

    .sk-circle12 {
      @include transform(rotate(330deg));

      &:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }
    }

    @-webkit-keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        @include transform(scale(0));
      } 40% {
        @include transform(scale(1));
      }
    }

    @keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        @include transform(scale(0));
      } 40% {
        @include transform(scale(1));
      }
    }
  }

  > p {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    bottom: 0;
    font-size: 24px;
    font-family: monospace;
    font-weight: bold;
    color: $gestorando;
    height: 20px;
    margin: auto;
    text-align: center;

    @include bp-small-mobile {
      font-size: 16px;
    }
  }
}
