// Checkboxs
@mixin checkbox {
  > p {
    margin: 0;
  }

  > div {
    width: 100%;
    height: 45px;
    position: relative;
    margin: auto auto 10px;
    border: 1px solid $gray;
    background-color: $white;
    border-radius: 5px;
    @include transition(all, 0.2s, ease-in);

    @media only screen and (max-width: 330px) {
      font-size: 14px;
    }

    &:not(:only-child) {
      max-width: 197px;
      display: inline-block;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      @include bp-small-mobile {
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &.selected {
      border: 2px solid $gestorando;
      vertical-align: top;

      label {
        background-color: $gestorando;
        color: $white;
        border-radius: initial;

        i {
          @include transform(scale(1));
        }
      }

      &:hover {
        border: 2px solid $gestorando;

        label {
          color: $white;
        }
      }
    }

    &:hover {
      border: 2px solid $gestorando;
      vertical-align: top;

      label {
        color: $gestorando;
      }

      @include bp-mobile {
        border: 1px solid $gray;

        label {
          color: inherit;
        }
      }
    }

    label {
      width: 100%;
      height: 100%;
      margin: 0;
      text-align: center;
      border-radius: 5px;
      line-height: 2.5;
      cursor: pointer;
      @include transition(all, 0.2s, ease-in);
      @include user-select;

      @media only screen and (max-width: 330px) {
        line-height: 3;
      }

      input {
        display: none;
      }

      i {
        color: $white;
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 1.7;
        font-size: 25px;
        @include transform(scale(0));
        @include transition(all, 0.2s, ease-in);
      }
    }
  }
}
