.no-match-page {
  background-color: $white;
  height: 100vh;

  .container {
    > div {
      margin: 30px auto 0;
      max-width: 1000px;

      h1 {
        font-size: 120px;
        font-family: 'Avenir-Heavy';

        @include bp-mobile {
          display: none;
        }
      }

      h2 {
        font-size: 1.2rem;
      }

      h6 {
        font-weight: bold;
      }

      img {
        margin-top: 80px;

        @include bp-mobile {
          margin-top: 0;
        }
      }
    }
  }
}
