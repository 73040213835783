&.account {
  //max-width: 450px;
  //margin-left: auto;
  //margin-right: auto;
  @include transition(all, 0.3s, ease-in-out);

  @include bp-small-mobile {
    max-width: initial;
    width: initial;
  }

  .ant-form-item-label {
    text-align: left;

    @include bp-small-mobile {
      padding: 0;

      label {
        margin: 0;
      }
    }
    //
    //label {
    //  margin: 0;
    //}
  }

  .ant-input-group-compact {
    .ant-select {
      width: 30%;
    }

    .ant-input {
      width: 40%;
    }
  }

  .ant-form-item {
    margin-bottom: 8px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    @include bp-mobile {
      max-width: 100%;
    }
  }

  .ant-form-explain {
    text-align: left;
  }

  .ant-card {
    max-width: 720px;
    margin: 0 auto;

    &-body {
      > h5 {
        + button {
          float: right;

          @include bp-tablet {
            float: left;
            margin-bottom: 15px;
          }
        }
      }
    }

    form {
      .ant-form-item-label,
      .ant-form-item-label label {
        padding: 0;
        margin: 0;
      }

      &.hide-inputs {
        input {
          padding: 0;
          color: rgba(0, 0, 0, 0.25);
          border: none;
          background-color: unset;
          cursor: text;
          font-family: 'Avenir-Heavy';
        }

        .phone-inlined {
          padding: 0;
          margin: 0;
          color: rgba(0, 0, 0, 0.25);
          text-align: left;
          font-family: 'Avenir-Heavy';
        }

        .ant-select-selection {
          border: none;
          background-color: unset;
          cursor: text;

          .anticon {
            display: none;
          }

          div {
            color: rgba(0, 0, 0, 0.65);
          }

          &:active,
          &:focus {
            outline: none;
            box-shadow: unset;
          }
        }

        .ant-form-item-children-icon {
          display: none;
        }

        button {
          display: none;
        }

      }

      button {
        margin-top: 16px;

        @include bp-mobile {
          width: 100%;
        }

        &:not([disabled]) {
          background-color: $success;
          border-color: $success;
        }
      }
    }
  }
}
