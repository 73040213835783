.request-progress-bar {
  margin: 0 !important;
  position: fixed;
  top: -6px;
  left: 0;
  right: 0;
  z-index: 9999;
  line-height: 1;

  .ant-progress {
    &-inner {
      border-radius: 0;

      .ant-progress-bg {
        background-color: $success !important;
        @include transition(all, 1.2s, ease-in);
      }
    }

    &-outer {
      margin: 0;
      padding: 0;
    }
  }
}
