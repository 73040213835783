// Selects
@mixin select {
  text-align: center;

  p.error-message {
    color: $error;
    display: none;
    margin: 0;
    z-index: 1;
    @include transform(scale(0));
    @include transition(all, 0.2s, ease-in);
  }

  > div {
    width: 100%;
    position: relative;
    margin: auto auto 10px;
    text-align: left;
    vertical-align: top;

    //&:not(:only-child) {
    //  max-width: calc(50% - 5px);
    //  display: inline-block;
    //
    //  &:first-child {
    //    margin-right: 5px;
    //  }
    //
    //  &:last-child {
    //    margin-left: 5px;
    //  }
    //
    //  @include bp-small-mobile {
    //    max-width: 100%;
    //    margin-left: auto !important;
    //    margin-right: auto !important;
    //  }
    //}

    > p {
      margin: 0;
    }

    &.valid {
      select {
        border-color: $success;

        &:focus {
          border-color: $success;
        }
      }

      i.fa-check-circle {
        color: $success;
        @include transform(scale(1));
      }
    }

    &.invalid {
      select {
        border-color: $error;

        &:focus {
          border-color: $error;
        }
      }

      p.error-message {
        display: block;
        @include transform(scale(1));
      }

      i.fa-times-circle {
        color: $error;
        @include transform(scale(1));
      }
    }

    label {
      height: 45px;
      margin: 0;
      width: 100%;
      text-align: left;
      cursor: pointer;
      position: relative;

      select {
        width: 100%;
        height: 100% !important;
        border: 2px solid #c9c9c9;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
          border-color: $gestorando;
        }
      }
    }

    i:not(.fa-check-circle):not(.fa-times-circle) {
      position: absolute;
      top: 13px;
      left: 10px;
      color: $icon-color;
    }

    .fa-check-circle,
    .fa-times-circle {
      position: absolute;
      right: 25px;
      top: 0;
      line-height: 1.8;
      font-size: 25px;
      @include transform(scale(0));
      @include transition(all, 0.2s, ease-in);
    }
  }
}
