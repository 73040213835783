.btn-back {
  @include btn;
  background-color: $btn-back-gray;
  color: #929aa4;
  width: 90px;
  float: left;
  padding: 0 10px;

  i {
    margin-right: 10px;
  }

  &:hover {
    background-color: darken($btn-back-gray, 7%);
    color: $body;
    text-decoration: none;

    @include bp-mobile {
      background-color: $btn-back-gray;
    }
  }

  &:focus {
    background-color: darken($btn-back-gray, 7%);
  }

  @include bp-small-mobile {
    border-radius: 3px;
    border: 1px solid darken($btn-back-gray, 20%);
    width: calc(50% - 5px);
  }
}

.btn-next {
  @include btn;
  background-color: $cyan;
  color: $dark-blue !important;
  width: 100%;
  max-width: 200px;
  border-radius: 25px;
  margin: auto;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 30px;
    height: calc(100% + 20px);
    position: absolute;
    top: -10px;
    left: -50px;
    background-color: $cyan;
    transform: rotateZ(25deg);
    @include transition(all, 0.4s, ease-in);

    @include bp-mobile {
      content: unset;
    }
  }

  &:hover {
    color: $gestorando;
    text-decoration: none;
  }

  &:focus {
    background-color: darken($cyan, 7%);
  }

  &[disabled] {
    color: $white !important;
    background-color: $disabled;
    cursor: not-allowed;

    &:focus {
      background-color: $disabled;
      cursor: not-allowed;
    }
  }

  &:not([disabled]):hover {
    background-color: darken($cyan, 7%);

    i {
      transform: translateX(50px);

      @include bp-small-mobile {
        transform: none;
      }
    }

    @include bp-mobile {
      background-color: $cyan;
    }
  }

  &.remove-disable {
    -webkit-animation: fillBackground 0.9s 1 ease-in both;
    animation: fillBackground 0.9s 1 ease-in both;

    @include bp-mobile {
      animation-duration: 0.4s;
    }

    @-webkit-keyframes fillBackground {
      0% {
        background-color: $gray;
      }

      100% {
        background-color: $cyan;
      }
    }

    @keyframes fillBackground {
      0% {
        background-color: $gray;
      }

      100% {
        background-color: $cyan;
      }
    }

    &:after {
      -webkit-animation: translate 0.6s 1 ease-in;
      animation: translate 0.6s 1 ease-in;

      @-webkit-keyframes translate {
        0% {
          left: -50px;
        }

        100% {
          left: 420px;
        }
      }

      @keyframes translate {
        0% {
          left: -50px;
        }

        100% {
          left: 420px;
        }
      }
    }
  }

  &--modal {
    background-color: $cyan !important;
    border-radius: 25px !important;
    max-width: 200px;
  }

  &--green {
    background-color: $success;
  }

  i {
    margin-left: 10px;

    @include bp-small-mobile {
      margin-left: 0;
      margin-right: 10px;
      padding-top: 13px;
      float: right;
    }
  }
}

.btns-next-back-wrapper {
  height: 43px;
  text-align: center;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  @include transition(all, 0.2s, ease-in);

  @include bp-small-mobile {
    text-align: center;
    padding: 10px;
    margin-top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $gray;
    width: 100%;
    height: 63px;
  }

  button,
  a {
    display: inline-block;

    &.btn-next {
      position: absolute;
      left: 0;
      right: 0;

      &[disabled] {
        pointer-events: none;

        @include bp-small-mobile {
          border: 1px solid darken($disabled, 10%);
        }
      }

      @include bp-mobile {
        position: relative;
        width: 100%;
      }

      @include bp-small-mobile {
        border-radius: 25px;
        border: 1px solid darken($cyan, 20%);
      }
    }

    &.btn-back {
      margin-right: 10px;

      @include bp-small-mobile {
        margin-right: 0;
      }

      + .btn-next {
        @include bp-mobile {
          position: relative;
          width: calc(100% - 100px);
        }

        @include bp-small-mobile {
          width: calc(50% - 5px);
        }
      }
    }
  }
}

.btn-success {
  background-color: $success;
  border-color: $success;
  box-shadow: 2px 2px 5px rgba(41, 41, 64, 0.3);
}
