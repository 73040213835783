.card-options {
  width: 100%;
  max-width: 300px;
  height: 200px;
  @include border-radius(3px);
  @include flexbox();
  @include flex-grow(1);
  @include transition(all, 0.2s, ease-in);
  @include flex-direction(column);
  position: relative;

  &:hover {
    text-decoration: unset;

    > .ant-card {
      border: 2px solid #1890ff;
    }
  }

  @include bp-big-tablet {
    max-width: calc(50% - 8px);
    margin-bottom: 16px;

    &:first-of-type,
    &:last-of-type {
      margin-right: 16px;
    }
  }

  @include bp-small-mobile {
    max-width: 100%;
    margin-right: 0 !important;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }

  &-wrapper {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-content(flex-start);

    &--short {
      .card-options {
        height: max-content;
        max-width: 310px;

        &:hover {
          > .ant-card {
            border: 2px solid #222538 !important;
          }
        }

        .ant-card {
          background-color: #1890ff;
          border: 1px solid #222538 !important;
          @include transition(all, 0.2s, ease-in);

          &:hover {
            background-color: #40a9ff;
            border-color: #40a9ff;
          }

          &-meta {
            &-title {
              margin: 0;
              @include flexbox();
              @include align-items(center);

              h4 {
                color: $white;
                line-height: 1;

                @include bp-tablet {
                  font-size: 1.2rem;
                  margin-top: 4px;
                }
              }

              i {
                margin-right: 10px;
                color: $white;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }

  .ant-card {
    height: 100%;

    &-body {
      height: 100%;
    }

    &-meta {
      height: 100%;

      &-title {
        margin: 0 0 8px;

        h4 {
          margin-bottom: 0;
        }
      }

      &-detail {
        @include flexbox();
        @include flex-grow(1);
        @include flex-direction(column);
        height: 100%;
        position: relative;
      }

      &-description {
        @include flexbox();
        @include flex-grow(1);
        @include flex-direction(column);

        button {
          margin-top: auto;
          max-width: 300px;
        }
      }
    }
  }
}
