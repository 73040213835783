.category-result-wrapper {
  > div:not(.modal) {
    max-width: 400px;
    width: 100%;
    margin: auto;

    > ul {
      color: $body;
      padding: 0;
      margin: 0;
      list-style: none;
      border-radius: 7px;
      background-color: #dff0d8;

      @include bp-small-mobile {
        font-size: 15px;
      }

      li {
        padding: 12px 0 12px 12px;

        span {
          font-family: 'Avenir-Heavy';
        }
      }
    }
  }

  .open-modal-categories-btn {
    color: $gestorando;
    margin-top: 25px;
    width: 100%;
    padding: 0;
    cursor: pointer;
    text-align: justify;
  }

  .modal {
    &:before {
      content: '';
      width: 100vw;
      height: 100vh;
      background-color: $black;
      opacity: 0.5;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .table-categories {

      .cyan-category {
        background-color: #00bcd4;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 15px;
        width: 20px;
        height: 20px;
        border-radius: 15px;

        &-text {
          display: inline-block;
          margin-top: 0;
          font-size: 14px;
        }
      }

      table {
        width: 100%;

        @include bp-small-mobile {
          font-size: 14px;
        }

        thead {
          tr {
            th {
              padding: 4px;
              background-color: rgba(0,0,0,0.11);
              border: 1px solid #ccc !important;
              text-align: center;
              vertical-align: top;
              font-family: 'Avenir-Heavy';
            }
          }
        }

        tbody {
          tr {
            &:hover {
              background-color: $gray;
            }

            &.active-category {
              td {
                border: 2px solid #00bcd4 !important;
              }
            }

            td {
              padding: 6px !important;
              border: 1px solid #ccc !important;
            }
          }
        }
      }
    }
  }
}
