// Inputs
@mixin input {
  > div {
    width: 100%;
    position: relative;
    margin: auto auto 10px;
    text-align: left;
    vertical-align: top;

    p.error-message {
      color: $error;
      display: none;
      margin: 0;
      z-index: 1;
      @include transform(scale(0));
      @include transition(all, 0.2s, ease-in);
    }

    &:not(:only-child) {
      max-width: calc(50% - 5px);
      display: inline-block;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      @include bp-small-mobile {
        max-width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    > p {
      margin: 0;
    }

    &.valid {
      input {
        border-color: $success;

        &:focus {
          border-color: $success;
        }
      }

      i.fa-check-circle {
        color: $success;
        @include transform(scale(1));
      }
    }

    &.invalid {
      input {
        border-color: $error;

        &:focus {
          border-color: $error;
        }
      }

      p.error-message {
        display: block;
        @include transform(scale(1));
      }

      i.fa-times-circle {
        color: $error;
        @include transform(scale(1));
      }
    }

    label {
      width: 100%;
      height: 45px;
      margin: 0;
      text-align: left;
      cursor: pointer;
      position: relative;

      @for $i from 1 through 2 {
        &:first-child:nth-last-child(#{$i}),
        &:first-child:nth-last-child(#{$i}) ~ label {
          @if $i == 1 {
            width: 100% / $i;
          } @else {
            width: 95% / $i;
            margin: 0 5% / $i;
          }
        }
      }

      &.show-password {
        .btn-show-password {
          @include transform(scale(0));
        }

        .btn-hide-password{
          @include transform(scale(1));
        }
      }

      &.hide-password {
        .btn-show-password {
          @include transform(scale(1));
        }

        .btn-hide-password{
          @include transform(scale(0));
        }
      }

      input {
        width: 100%;
        height: 100%;
        border: 2px solid $gray;
        border-radius: 5px;
        padding: 0 10px;

        &::-webkit-credentials-auto-fill-button {
          visibility: hidden;
          display: none !important;
          pointer-events: none;
          height: 0;
          width: 0;
          margin: 0;
        }

        &:focus {
          outline: none;
          border-color: $gestorando;
        }
      }

      i:not(.fa-check-circle):not(.fa-times-circle):not(.fa-eye):not(.fa-eye-slash) {
        position: absolute;
        top: 13px;
        left: 10px;
        color: $icon-color;
      }

      .btn-show-password,
      .btn-hide-password {
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 2;
        height: 100%;
        font-size: 25px;
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: unset;
        @include transform(scale(0));
        @include transition(all, 0.2s, ease-in);

        &:focus {
          outline: none;
        }

        i {
          color: $gestorando;
        }
      }

      .fa-check-circle,
      .fa-times-circle {
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 1.8;
        font-size: 25px;
        @include transform(scale(0));
        @include transition(all, 0.2s, ease-in);
      }
    }
  }
}

// Input placeholder color
@mixin inputPlaceholder($color) {
  input,
  textarea {
    /* Chrome/Opera/Safari */
    &::-webkit-input-placeholder {
      color: $color !important;
      font-style: normal;
      line-height: 1 !important;
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      color: $color !important;
      font-style: normal;
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      color: $color !important;
      font-style: normal;
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: $color !important;
      font-style: normal;
    }
  }
}
