&.billing {
  .ant-table-wrapper {
    @include bp-big-tablet {
      display: none;
    }

    .ant-table-body {
      background-color: $white;
      margin: 0;
    }
  }

  .filtered-billing-mobile {
    display: none;
    margin-top: 16px;

    @include bp-big-tablet {
      display: block;
    }

    + .ant-divider {
      display: none;

      @include bp-big-tablet {
        display: block;
      }
    }

    .ant-card-body {
      padding: 10px !important;

      @include bp-tablet {
        padding: 15px !important;
      }
    }
  }

  .ant-list {
    display: none;

    @include bp-big-tablet {
      display: block;
    }

    &-item {
      border-bottom: unset;
      padding: 0;

      .tag-status {
        position: absolute;
        right: 15px;
        top: 22px;
      }

      .ant-tag {
        margin: 0;
      }

      > .ant-collapse {
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 4px;

        > .ant-collapse-item {
          border-radius: 0;
          border: unset !important;

          > .ant-collapse-content {
            border-radius: 0;

            .ant-card-meta {
              > div {
                > div {
                  display: inline-block;
                  margin: 0;
                  vertical-align: top;

                  &.ant-card-meta-title {
                    float: right;
                  }

                  &.ant-card-meta-description {
                    float: left;
                  }
                }
              }
            }
          }
        }
      }

      &:last-of-type {
        > .ant-collapse {
          > .ant-collapse-item {
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}
