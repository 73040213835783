.content {
  width: calc(100% - 200px);
  margin-left: 200px;
  display: inline-block;
  vertical-align: top;

  @include bp-mobile {
    width: 100%;
    margin-left: 0;
    display: block;
  }

  > div {
    margin: 15px 15px 0;

    > div {
      overflow: hidden;
    }

    &.empty-message {
      margin-top: 20vh;

      h5 {
        margin: 0;
        font-family: 'Avenir-Light';
        text-align: center;
        line-height: 1.55;

        strong {
          font-family: 'Avenir-Heavy';
        }
      }
    }

    li {
      button {
        color: $gestorando;
        text-decoration: underline;
        padding: 0;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    .link {
      color: $gestorando;
      text-decoration: underline;
      padding: 0;
      cursor: pointer;
      text-align: left;
      display: inline-block;
      width: auto;
    }

    .ant-card {
      margin-bottom: 10px;

      &-body {
        @include min-bp-mobile() {
          padding: 15px 24px;
        }

        > a {
          overflow: hidden;
          color: initial;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: block;
          @include transition(all, 0.4s, cubic-bezier(0.46, 0.03, 0.52, 0.96));

          &:hover {
            > div:first-of-type {
              text-decoration: underline;
            }
          }

          > div {
            padding: 0;
            cursor: pointer;
            text-align: left;
            display: block;
            width: 100%;
            position: relative;

            &:focus {
              outline: none;
            }

            &:hover {
              .subtitle {
                text-decoration: underline;

                @include bp-mobile {
                  text-decoration: none;
                }
              }
            }

            .subtitle {
              color: $gestorando;
              margin: 0;
              padding-right: 15px;
              display: inline-block;

              + i {
                display: inline-block;
                position: absolute;
                right: 0;
                top: 4px;
                @include transition(all, 0.4s, cubic-bezier(0.46, 0.03, 0.52, 0.96));
              }
            }
          }

          &.active {
            .subtitle {
              + i {
                transform: rotateX(180deg);
              }
            }

            + div {
              opacity: 1;
              margin-top: 15px;
              height: initial;
            }
          }
        }

        > div {
          opacity: 0;
          height: 0;
          @include transition(all, 0.4s, cubic-bezier(0.46, 0.03, 0.52, 0.96));

          p:last-child,
          ul:last-child {
            margin-bottom: 0;
          }

          ul, ol, p, button {
            font-size: 14px;

            @include bp-mobile {
              font-size: 1rem;
            }
          }

          .toggle-convenios-table {
            padding: 0;
            text-align: left;
            margin-bottom: 1rem;
            display: block;

            i {
              color: $gestorando;
              vertical-align: text-bottom;
              @include transition(all, 0.2s, ease-in-out);
            }

            + table {
              display: none;
              @include transition(all, 0.2s, ease-in-out);
            }

            &.active {
              i {
                transform: rotate(45deg);
                color: $error;
              }

              + table {
                display: block;
              }
            }
          }

          .toggle-sub-list {
            padding: 0;
            text-align: left;
            margin-bottom: 1rem;
            display: block;

            i {
              color: $gestorando;
              vertical-align: text-bottom;
              @include transition(all, 0.2s, ease-in-out);
            }

            + ul {
              display: none;
              @include transition(all, 0.2s, ease-in-out);
            }

            &.active {
              i {
                transform: rotate(45deg);
                color: $error;
              }

              + ul {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
