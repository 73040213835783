&.plan {
  text-align: left;

  h2 {
    text-align: center;
  }

  > div {
    display: inline-block;
		text-align: center;
		margin: 0;
    // max-width: 375px;
		max-width: calc((100% / 3) - 27px);
		vertical-align: top;
    height: 500px;
		width: 100%;
		box-sizing: border-box;
		position: relative;
		padding: 0;
		border: 4px solid lighten(#dfdfdf, 5%);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: $white;
    overflow: hidden;
		color: #393a3d;
    z-index: 1;
		@include transition(all ,0.2s, ease-out);

    i {
      display: none;
    }

    &:first-of-type {
      margin-right: 40px;
      height: 420px;
    }

    &:last-of-type {
      margin-left: 40px;
      height: 600px;

      .special-offer {
        background-color: orange;
      }
    }

    &.selected {
      border-color: lighten($gestorando, 10%);

      .title {
        display: inline-block;
      }

      i {
        display: inline-block;
        position: absolute;
        color: lighten($gestorando, 10%);
        font-size: 48px;
        top: 24px;
        transform: translateX(10px);
      }

      .price {
        color: $white;
        animation-name: backgroundColor;
        animation-iteration-count: 1;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;

        @keyframes backgroundColor {
          100% {
            background-color: lighten($gestorando, 10%);
          }
        }

        &:after {
          @include transition(all ,1s, ease-out);
          transform: skew(-20deg) translateX(100px);
        }
      }

      .btn {
        pointer-events: none;
      }
    }

    ul {
      text-align: left;
      margin-top: 0;

      li {
        margin-top: 10px;
      }
    }

    .special-offer {
			display: block;
			background-color: $success;
			padding: 10px 30px;
      margin: 0;
			position: absolute;
			color: $white;
			top: 7%;
      left: 33%;
			width: 100%;
      transform: rotate(42deg);
			border-top-left-radius: .25rem;
			border-top-right-radius: .25rem;
		}

    .title {
      height: 85px;
  		font-family: 'Avenir-Heavy';
  		font-size: 1.5rem;
  		color: lighten(#393a3d, 10%);
  		margin: 0;
      padding: 32.5px 0 24.5px;
    }

    .price {
			font-family: 'Avenir-Heavy';
			font-size: 2.5rem;
			color: lighten(#393a3d, 20%);
			background-color: lighten(#dfdfdf, 5%);
			line-height: 1;
			margin: 0;
			padding: 15px 0;
			position: relative;
			overflow: hidden;
			@include transition(all ,0.2s, ease-out);

			@include bp-mobile {
				margin-top: 0;
			}

			&:after {
				content: "";
				position: absolute;
				transform: skew(-20deg) translateX(-350px);
				width: 15px;
				height: 100%;
				top: 0;
				background-color: lighten($gestorando, 20%);
				z-index: -1;
			}

			span {
				&:last-child {
					font-size: 1.5rem;
					font-family: 'Avenir-Light';
				}
			}
		}

    .btn {
			color: $white;
			font-size: 1rem;
			text-transform: capitalize;
			box-sizing: padding-box;
			border-radius: .25rem;
			border: none;
			// background-color: lighten($gestorando, 10%);
			// box-shadow: 0 4px 0 $gestorando;
			padding: 10px 30px;
			margin: auto;
			max-width: fit-content;
			width: 100%;
			cursor: pointer;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25px;
			overflow: hidden;
			z-index: 2;

      &:disabled {
        cursor: not-allowed;
      }

			&:focus {
				outline: none;
				background-color: $gestorando;
				box-shadow: 0 4px 0 lighten($gestorando, 10%);
			}
    }
  }
}
