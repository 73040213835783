.response-wrapper {
  margin: 0 auto;
  text-align: center;
  position: relative;

  .ant-alert {
    padding-left: 15px;
  }

  i {
    display: none;
    font-size: 54px;
    position: absolute;
    top: 15px;
    left: 25px;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    @include transition(all, 0.2s, ease-in);

    @include bp-small-mobile {
      font-size: 34px;
      top: 20px;
      left: 6vw;
    }

    &.fa-check-circle {
      color: $success;
      animation-name: animationSuccess;

      @keyframes animationSuccess {
        100% {
          @include transform(rotateY(720deg));
        }
      }
    }

    &.fa-times-circle {
      color: $error;
      transform: scale(0);
      animation-name: animationError;

      @keyframes animationError {
        100% {
          @include transform(scale(1));
        }
      }
    }

    &.fa-exclamation-triangle {
      color: $warning;
      animation-name: animationWarning;

      @keyframes animationWarning {
        0% {
          @include transform(translateY(100px));
          opacity: 0;
        }

        100% {
          @include transform(translateY(0));
          opacity: 1;
        }
      }
    }
  }

  .title {
    margin-top: 0;
    font-size: 24px;

    @include bp-small-mobile {
      font-size: 22px;
    }
  }

  .description {
    margin: 0;
    font-size: 18px;
    text-align: left;

    span {
      text-decoration: underline;
      font-family: 'Avenir-Heavy';
    }

    @include bp-small-mobile {
      font-size: 1rem;
    }
  }
}
