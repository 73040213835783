.server-problem {
  h1 {
    font-size: 100px;
    font-family: 'Avenir-Heavy';
  }

  h2 {
    font-size: 1.2rem;
  }

  h6 {
    font-weight: bold;
  }

  img {
    margin-top: 80px;
  }

  button {
    margin-top: 15px;
  }
}
