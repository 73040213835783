.ant-layout {
  background-color: #f2f2f2;
}

.ant-divider-horizontal {
  margin: 15px 0;
}

.ant-alert {
  margin-bottom: 15px;
  color: $body;
}

.ant-steps {
  &-icon {
    i.anticon-check {
      vertical-align: text-bottom;
    }
  }
}

.ant-notification {
  @include bp-mobile {
    width: 100%;
    max-width: calc(100% - 30px);
    margin: 0 15px;
    left: 0;
    right: 0;
    top: 15px !important;

    &-notice {
      margin-bottom: 0;
      -webkit-box-shadow: 0 10px 12px rgba(0, 0, 0, 0.5);
      box-shadow: 0 10px 12px rgba(0, 0, 0, 0.5);
    }
  }
}

.ant-btn {
  .anticon {
    vertical-align: text-bottom;
    padding-bottom: 1px;
  }
}

.ant-select-auto-complete {
  .ant-select-selection {
    border-color: unset !important;
    border-right-width: unset !important;
    outline: unset !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;

    .ant-select-search {
      padding: 0 !important;
    }
  }
}

.ant-input,
.ant-input-number,
.ant-calendar-picker {
  width: 100%;
}

textarea.ant-input {
  margin-bottom: 0 !important;
}

.ant-calendar-picker {
  i {
    &.anticon {
      &.anticon-close-circle,
      &.anticon-calendar {
        font-size: 12px;
        right: 11px;
      }
    }
  }
}

.ant-row {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.ant-pagination {
  &-item {
    &-active {
      background-color: $white;
    }

    &-link {
      i {
        vertical-align: text-top;
        line-height: 1;
      }
    }
  }
}

.ant-form-item {
  margin-bottom: 0;

  &-control {
    line-height: 1;

    &.is-validating {
      .ant-input-suffix {
        right: 30px;
        pointer-events: none;
      }
    }

    input[disabled] {
      + .ant-input-suffix {
        z-index: -1;
      }
    }

    .ant-form-item-children {
      display: block;
    }
  }

  &-label {
    line-height: 1;

    padding: 0;

    label {
      i {
        font-size: 16px;
        vertical-align: text-top;
        margin-left: 3px;
      }
    }
  }
}

.ant-message {
  .anticon {
    vertical-align: top;
  }
}

.ant-card {
  border-radius: 4px;
  color: inherit;
  font-size: 1rem;

  &-body {
    padding: 24px;

    @include bp-mobile {
      padding: 15px;
    }
  }
}

.ant-modal {
  margin: 0 auto;
}

.ant-menu-submenu.ant-menu-submenu-popup  {
  ul {
    position: absolute;
    background: $white;
    z-index: 1;
  }
}

.ant-calendar {
  @include bp-mobile {
    width: 100%;
  }

  &-range-part {
    @include bp-mobile {
      width: 100%;
    }
  }
}
.ant-input-group-addon {
  .ant-select {
    width: auto;
  }
}

.ant-select {
  font-size: 1rem;
  width: 100%;

  &-selection {
    &__rendered {
      > ul > li {
        padding-left: 5px !important;
        padding-right: 26px !important;

        p {
          margin: 0 0 0 7px;
          display: inline-block;
          vertical-align: -0.5px;
        }

        img {
          width: 20px;
          display: inline-block;
        }

        i {
          font-size: 18px;
          vertical-align: initial;
        }
      }
    }

    &__clear {
      i.anticon.anticon-close-circle {
        vertical-align: top;
      }
    }

    &__placeholder {
      color: #e4e4e4;
      font-size: 14px;
    }
  }

  &-dropdown {
    width: auto !important;

    i {
      font-size: 18px;
      padding-right: 5px;
      vertical-align: initial;
    }

    p {
      margin: 0 0 0 5px;
      display: inline-block;
      vertical-align: middle;
    }

    &-menu {
      max-height: 100%;
    }
  }
}

.ant-input {
  &:not([type="textarea"]) {
    font-size: 1rem;
    line-height: 0.8;
  }

  &[type="textarea"] {
    font-size: 1rem;
    line-height: 1.5;

    /* Chrome/Opera/Safari */
    &::-webkit-input-placeholder {
      padding-top: 3px;
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      padding-top: 3px;
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      padding-top: 3px;
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      padding-top: 3px;
    }
  }
}

.ant-menu-submenu.ant-menu-submenu-popup {
  background-color: $white;

  li {
    margin: 0 !important;

    &.ant-menu-item {
      padding-left: 17px !important;

      &-selected {
        background-color: #1890ff;
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
//
// .ant-calendar-picker-container {
//   .ant-calendar {
//     @include bp-mobile {
//       max-width: 320px;
//     }
//
//     &-panel {
//       .ant-calendar-range-middle {
//         @include bp-mobile {
//           display: none;
//         }
//       }
//
//       .ant-calendar-range-part {
//         .ant-calendar-input-wrap {
//           > div {
//             @include bp-mobile {
//               margin-left: 0;
//             }
//           }
//
//           input {
//             font-size: 1rem;
//           }
//         }
//       }
//     }
//   }
// }
