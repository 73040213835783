.sidebar {
  position: -webkit-sticky;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 60px;
  height: 100%;
  width: 200px;
  margin-left: 200px;
  overflow: auto;
  z-index: 1;
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  @include transition(all, 0.2s, ease-in-out);

  @include bp-mobile {
    position: relative;
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    display: block;
    -webkit-overflow-scrolling: touch;
    height: auto;
    border-bottom: 1px solid #cccccc7a;
    border-right: unset;
  }

  &.collapsed {
    margin-left: 80px;

    @include bp-mobile {
      margin-left: 0;
    }
  }

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;

    @include bp-mobile {
      white-space: nowrap;
      width: 100%;
      height: 100%;
      padding: 10px 0;
    }

    > li {
      @include transition(all, 0.2s, ease-in-out);

      @include bp-mobile {
        height: 55px;
        line-height: 50px;
        padding: 0 5px;
        display: inline-block;
        background-color: $white;
        border: 2px solid transparent;
        border-radius: 6px;
        margin: 0 5px;
        box-shadow: 2px 2px 1px #ccc;
      }


      a {
        padding: 0;
        width: 100%;
        text-align: left;

        &:focus {
          outline: none;
        }

        .title {
          display: inline-block;
          cursor: pointer;
          width: 100%;
          padding: 15px 0 0 15px;

          @include bp-mobile {
            padding: 0;
          }

          &:hover {
            i {
              color: $body;
            }

            p {
              color: $body;
            }
          }

          i {
            display: inline-block;
            color: #969696;
            font-size: 18px;
            vertical-align: text-bottom;
            @include transition(all, 0.2s, ease-in-out);

            @include bp-mobile {
              display: none;
            }
          }

          p {
            display: inline-block;
            margin: 0 0 0 10px;
            font-size: 14px;
            color: #969696;

            @include bp-mobile {
              margin: 0;
              color: $body;
            }
          }

          &::after {
            content: '';
            height: 1px;
            width: 80%;
            margin: 15px 0 0 auto;
            display: block;
            background: -webkit-gradient(linear, left top, right top, color-stop(10%, #C0C0C0), color-stop(0%, rgba(186, 186, 186, 0.001)));
            background: linear-gradient(to right, #C0C0C0 10%, rgba(186, 186, 186, 0.001) 0%);
            background-repeat: repeat-x;
            background-position: top left;
            background-size: 8px 1px;

            @include bp-mobile {
              content: unset;
            }
          }
        }

        p {
          margin: 0;
        }

        &.active {
          @include bp-mobile {
            border: 2px solid #5772fe;
          }

          .title {
            border-right: 2px solid #5772fe;

            @include bp-mobile {
              border-right: unset;
            }

            i {
              color: #5772fe;
            }

            p {
              color: $body;
              font-family: 'Avenir-Heavy';
            }

            &:hover {
              i {
                color: #5772fe;
              }
            }
          }
        }
      }
    }

    .description {
      display: none;
    }
  }
}
