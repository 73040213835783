.box-styles {
  border: none;
  background-color: $white;
  border-top: 3px solid $gestorando;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  padding: 0;

  @include bp-mobile {
    padding: 0;
  }
}
