.fiscal-profile {
  > div {
    > div {
      @include bp-tablet {
        &:last-of-type {
          .ant-card {
            margin-top: 15px;
          }
        }
      }
    }
  }

  .ant-card {
    &-body {
      > h5 {
        display: inline-block;

        + span {
          display: inline-block;
          border-radius: 4px;
          background-color: #eee;
          width: 42px;
          height: 42px;
          line-height: 2.4;
          border: 1px solid #e8e8e8;
          text-align: center;
          vertical-align: text-bottom;
          margin-left: 10px;
          font-size: 18px;
          font-family: "Avenir-Heavy";

          @include bp-small-mobile {
            width: 26px;
            height: 26px;
            line-height: 1.5;
          }
        }
      }

      .ant-list {
        &-items {
          color: $body;
        }

        &-item {
          @include flexbox();
          @include justify-content(space-between);

          p {
            margin: 0;
          }
        }
      }
    }

    button {
      color: $gestorando;
      display: block;
      margin: 15px auto 0;
    }
  }
}
.fiscal-footer {
  vertical-align: middle;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(1, 150px);
}
.fiscal-profile .ant-card button {
  color: #fff;
  display: block;
  margin: 0px !important;
}
.fiscal-footer .link {
  vertical-align: middle;
  padding-top: 5px;
}
.h5-category-mono {
  margin-right: 1em;
}

.row .category-mono-row {
  display: grid;
  grid-template-columns: 90% 10%;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.footer-resumen-buttons {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1rem;
}
.mixed-chart {
  width: 100%;
  padding: 0 15px;
}
.grid-statistics {
  padding-left: 0;
  display: grid;
  grid-template-columns: 80% 20%;
  height: 0.5rem;
}
.grid-statistics-head-title {
  padding-left: 0;
  display: grid;
  grid-template-columns: 80% 20%;
}
.grid-statistics_twelve_months {
  grid-gap: 0.5rem;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
@media screen and (max-width: 700px) {
  .grid-statistics_twelve_months {
    grid-gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.self-to-end {
  justify-self: end;
}

.price-statistics {
  margin-bottom: 0px !important;
}
.card-debts-shadow {
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
}

.title-statistic-months {
  font-size: 11px !important;
  margin-bottom: 0rem;
}

.statistic-card-padd .ant-card-body {
  padding: 10px !important;
}
.statistics-graph {
  margin-bottom: 1rem;
}
.recategorization-paragraph {
  font-size: 12px;
  margin-right: 0px !important;
}
.recategorization-paragraph p {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 700px) {
  .grid-statistics {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-statistics_twelve_months .box-wrapper {
    justify-items: center;
  }
}
.icon-title-recategorization {
  padding-left: 0.3rem;
  vertical-align: middle !important;
  font-size: 14px !important;
}

.recategorization-paragraph strong {
  font-size: 1.25rem;
}
.statistic-link span {
  margin-top: 0px !important;

  vertical-align: middle;
  color: #007bff;
  font-size: 11px !important;
  margin: 0px !important;
  background-color: transparent !important;
  border: transparent;
}
.statistic-link {
  margin: 0px !important;
  background-color: transparent !important;
  border: transparent;
  margin-right: 0px;
}

.last-twelve-months {
  display: grid;
  padding-left: 1rem;

  grid-template-columns: repeat(2, 1fr);
}
.inspect-facturacion {
  justify-self: end;
  padding-right: 1rem;
  font-size: 0.8rem;
}
.custom-progress-inicio {
  text-align: center;
  border-radius: 5px;
  border: 1px solid #212b36;
}
.custom-progress-inicio-to-end {
  text-align: end !important;
  padding-right: 2rem;
  border-radius: 5px;
  border: 1px solid #212b36;
}
.last-category-bar {
  background-color: #e9ecef;
  color: #212b36;
}
.last-category-b {
  text-align: center !important;
  padding-left: 10px;
}
.progress-barr {
  padding-top: 0.5rem;
  padding-left: 0 !important;
}
.emitir-facturas-buton {
  font-size: 12px !important;
}
.overpass-link-description {
  font-size: 10px;
  color: #007bff;
  cursor: pointer;
}
.bottom-text-description {
  font-size: 0.7rem;
  margin-bottom: 0 !important;
  text-align: start;
  padding-left: 5rem;
}
.danger {
  color: red;
  vertical-align: middle;
}
.fiscal-profile .ant-card-body {
  padding-bottom: 0.5rem !important;
}
.modal-saldo-a-favor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.in-alert {
  background-color: #2670b3 !important;
}
.btns-other-payment-wrapper {
  display: grid;
  row-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  grid-column-gap: 1rem;
}
.button-othet-payments {
  width: 100% !important;
}
.mercadopago-button {
  width: 100% !important;
  height: 65px !important;
  background-color: rgba(225, 225, 226, 0.6) !important;
  border-radius: 5px !important;
  text-align: center !important;
  padding: 0 !important;
  position: relative !important;
  transition: all 0.2s ease-out !important;
  color: #212529;
}
.options-to-do__buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.options-to-do__buttons p {
  font-size: 10px;
}
.options-to-do__buttons button {
  font-size: 10px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .proyeccion-categoria,
  div#crm-metrics div.app,
  .last-twelve-months a {
    display: none;
  }
  .h5-category-mono {
    font-size: 1.1em;
  }
}
@media screen and (min-width: 768px) {
  .options-to-do {
    display: none;
  }
}
