.internet-connection-message {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 3px 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  font-size: 14px;
  @include transition(all, 0.2s, linear);

  &.on {
    background-color: green;
  }

  &.off {
    background-color: $red;
    animation-name: show;
  }

  &.hideNow {
    transform: translateY(27px);
  }

  &.hideOnAfter3Sec {
    animation-name: hide;
    animation-delay: 3s;
  }

  @keyframes show {
    from {
      @include transform(translateY(27px));
    }

    to {
      @include transform(translateY(0));
    }
  }

  @keyframes hide {
    from {
      @include transform(translateY(0));
    }

    to {
      @include transform(translateY(27px));
    }
  }

  p {
    margin: 0;
    color: $white;
  }
}