.category-progress {
  text-align: center;
  margin: 30px 0;

  @include bp-mobile {
    display: none;
  }

  .text-before {
    width: 13%;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .progress {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 27% - 4%);
    max-width: 100%;
    margin-left: 2%;
    margin-right: 2%;

    > div {
      background-color: $gestorando;
    }
  }

  .text-after {
    width: 13%;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }
}
