.modal-user-wants-to-redirect-to-login {
  @include bp-mobile {
    width: calc(100% - 20px) !important;
  }
  .user-may--login {
    .ant-spin-nested-loading {
      display: flex;
      justify-content: center;
    }
  }
  .ant-modal-title {
    p {
      font-size: 19px;
      line-height: 1.5;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: unset;
    padding: 16px 24px;

    button {
      line-height: 1;
      max-width: 320px;

      &:after {
        content: unset;
      }

      &:last-of-type {
        margin: 20px auto 0;
        background-color: lighten($gestorando, 55%);
        color: $gestorando !important;
        border-color: $gestorando;
        border-radius: 25px;
      }
    }
  }
}
