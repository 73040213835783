.checkbox-wrapper {
  @include checkbox;

  &--compact {
    width: 50%;
    display: inline-block;

    > div {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 1px !important;

      &:hover {
        border-right-width: 2px !important;
      }
    }

    + .checkbox-wrapper {
      width: 50%;
      display: inline-block;

      > div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px !important;

        &:hover {
          border-left-width: 2px !important;
        }
      }
    }
  }
}
