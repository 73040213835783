@font-face {
  font-family: 'Avenir-Roman';
  src: url('../../fonts/AvenirLTStd-Roman.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Roman.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Roman.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('../../fonts/AvenirLTStd-Medium.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Medium.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Medium.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Medium.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('../../fonts/AvenirLTStd-Light.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Light.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Light.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Light.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../../fonts/AvenirLTStd-Heavy.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Heavy.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Heavy.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('../../fonts/AvenirLTStd-Book.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Book.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Book.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Book.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Black';
  src: url('../../fonts/AvenirLTStd-Black.otf') format('opentype'),
    url('../../fonts/AvenirLTStd-Black.ttf') format('truetype'),
    url('../../fonts/AvenirLTStd-Black.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Black.eot') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

h4, h5 {
  font-family: 'Avenir-Heavy';
}
