.signup {
  max-width: 600px;
  padding: 0;
  margin: 30px auto 0;
  @include transition(all, 0.2s, ease-in);

  @include bp-small-mobile {
    margin-top: 0;
    padding-bottom: 63px;
    max-width: initial;
    width: initial;
    box-shadow: unset;
    border: none;
    max-height: 930px;
  }

  &.extra-padding {
    @include bp-small-mobile {
      padding-bottom: 330px;
    }

    .btns-next-back-wrapper {
      @include bp-small-mobile {
        position: absolute !important;
        bottom: -63px !important;
      }
    }
  }

  &.phone-number-on-focus {
    @include bp-small-mobile {
      padding-bottom: 285px !important;
    }
  }

  .card {

    @include bp-small-mobile {
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.3);
      border: none;
    }

    &-body {

      .card-title {
        margin-bottom: 10px;
        text-align: center;

        @include bp-small-mobile {
          font-size: 20px;
        }

        + hr {
          margin-top: 0;
        }
      }

      .card-subtitle {
        text-align: center;
        margin: 0 0 20px;
      }

      > div {
        max-width: 700px;
        margin: auto;

        @include bp-mobile {
          max-width: 100%;
          text-align: center;
        }

        > .input-wrapper,
        > .select-wrapper {
          > div {
            margin-bottom: 20px;
          }
        }

        > .phone-wrapper {
          > div {
            max-width: calc(100% - 62%);
            display: inline-block;
            margin: 0 0 20px;

            &:nth-child(2) {
              margin-right: 7.5px;
              margin-left: 7.5px;
              max-width: 21%;
            }

            @include bp-small-mobile {
              max-width: 100%;

              &:nth-child(2) {
                margin-right: 0;
                margin-left: 0;
                max-width: 100%;
              }
            }
          }
        }

        input {
          padding-left: 35px;
        }

        select {
          text-indent: 26px;

          @include bp-small-mobile {
            text-indent: 20px;
          }
        }

        .checkbox-terminos-wrapper {
          > div {
            position: relative;

            label {
              margin: 0;
              width: 100%;

              input {
                margin-right: 10px;
              }
            }

            .largerCheckbox {
              zoom: 1.5;
            }
          }
        }
      }

      .btns-next-back-wrapper {
        @include min-bp-small-mobile {
          position: relative;
          margin-top: 50px;
          bottom: initial;
        }

        @include bp-small-mobile {
          margin-top: 0;
          z-index: 999;
          -webkit-transition: unset !important; /* Safari */
          -moz-transition: unset !important; /* Firefox */
          -o-transition: unset !important; /* Opera */
          transition: unset !important;  /* Non-prefixed version, Chrome */
        }
      }
    }
  }
}
