.welcome-message {
  h2 {
    text-align: center;
  }

  ol {
    li {
      max-width: 399px;
      margin-bottom: 20px;

      > .input-wrapper,
      > .select-wrapper {
        > div {
          margin-bottom: 0;
        }
      }

      .questions {
        .card-title {
          display: none;
        }
      }

      .checkbox-wrapper {
        > div {
          border-width: 2px;
        }
      }
    }
  }

  &.debe-pago {
    .ant-btn {
      &:hover {
        color: unset;
      }
    }

    .btn-reactivate-suscription {
      text-transform: uppercase;
      margin: 10px auto 0;
      background-color: $red;
      line-height: 2;
      @include transition(all, 0.4s, ease-in);

      &.a {
        background-color: $orange;
      }

      &.b {
        background-color: $success;
      }
    }

    .translate-components {
      position: relative;
      overflow: hidden;

      .btn-back-suscription {
        opacity: 0;
        padding: 0;
        visibility: hidden;
        display: none;
        cursor: pointer;
        font-size: 17px;
        @include transition(all, 0.4s, ease-in);

        &:focus {
          outline: none;
        }

        i {
          display: inline-block;
          vertical-align: text-bottom;
          margin-right: 5px;
        }
      }

      .our-service-table-wrapper {
        width: 60%;
        margin: auto;
        @include transition(all, 0.4s, ease-in);

        @include bp-tablet {
          width: 75%;
        }

        @include bp-mobile {
          width: 100%;
        }

        h4 {
          margin: 16px 0;
        }

        .ant-table-fixed {
          background-color: $white;

          .anticon-check-circle {
            color: $success;
            font-size: 24px;
          }
        }
      }

      .payment-method-wrapper {
        opacity: 0;
        position: absolute;
        top: 33px;
        visibility: hidden;
        @include transform(translateX(165px));
        @include transition(all, 0.4s, ease-in);

        .box-styles {
          padding: 0;
        }

        .ticket-generated {
          .btn-next {
            display: none;
          }
        }
      }

      &.active {
        overflow: visible;

        .btn-reactivate-suscription {
          opacity: 0;
          visibility: hidden;
          @include transform(translateX(50px));
        }

        .btn-back-suscription {
          opacity: 0.6;
          visibility: visible;
          position: absolute;
          top: -9px;
          left: 0;
          display: block;

          &:hover {
            opacity: 1;
          }
        }

        .our-service-table-wrapper {
          opacity: 0;
          visibility: hidden;
        }

        .payment-method-wrapper {
          opacity: 1;
          visibility: visible;
          @include transform(translateX(0));
        }
      }
    }
  }

  &.esperando-inscripcion {
    .ant-steps-item {
      &-icon {
        width: 32px;

        .ant-steps-icon {
          top: 0;
        }
      }

      &-description {
        .anticon-loading {
          vertical-align: top;
          margin-left: 5px;
        }
      }

      &-finish {
        .ant-steps-item-tail {
          &::after {
            background-color: $success;
          }
        }

        .ant-steps-item-icon {
          border-color: $success;

          .ant-steps-icon {
            top: -4px;
            left: 0;
            font-size: 32px;
            color: $success;
          }
        }
      }

      &-error {
        .ant-steps-icon {
          top: -3px;
        }
      }

      &-process {
        .ant-steps-item-icon {
          width: 32px;

          .ant-steps-icon {
            line-height: initial;
          }
        }
      }
    }
  }

  &.inscripto {
    margin-top: 15px;

    @include bp-big-tablet {
      margin-top: 15px;
    }

    button {
      padding: 0;
      color: $gestorando;
      cursor: pointer;
    }
  }

  &.no-inscripto {
    .form-wrapper {
      padding-left: 0;
      list-style: none;
      margin: 0;

      li {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e8e8e8;

        &:last-of-type,
        &:nth-last-child(2) {
          border-bottom: unset;
        }

        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        p {
          margin: 0;

          &.title {
            width: 90%;
            display: inline-block;

            + button {
              width: 10%;
              vertical-align: top;
              text-align: right;
              cursor: pointer;
              display: inline-block;
              padding: 0;

              &:focus {
                outline: none;
              }

              i.fa-question-circle {
                position: relative;
                font-size: 20px;
                left: unset;
                top: unset;
                right: unset;
                color: $gestorando;
              }
            }
          }
        }

        div {
          &.input-wrapper {
            input {
              background-color: $white;
            }
          }

          &.radio-wrapper {
            label {
              display: block;

              input {
                margin-right: 10px;
              }
            }
          }
        }

        .no-obra-social,
        .tiene-obra-social,
        .medicina-prepaga,
        .select-obra-social,
        .cuit-conyuge {
          display: none;
          position: relative;

          &.selected {
            display: block;
          }

          .select {
            &.disabled {
              opacity: 0.6;
              pointer-events: none;

              > div {
                cursor: not-allowed;
              }
            }
          }

          .show-info-obra-social-button {
            color: $gestorando;
            padding: 0;
          }

          .obra-social-info {
            display: none;

            span {
              font-family: "Avenir-Heavy";
            }
          }

          ~ .modal {
            &:before {
              content: "";
              width: 100vw;
              height: 100vh;
              background-color: $black;
              opacity: 0.5;
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }
        }

        .cuit-conyuge {
          height: 100%;
        }

        &:last-of-type {
          list-style: none;

          button {
            &[disabled] {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .ant-card {
      margin: 0 auto;
      // max-width: 720px;

      li {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.fiscal-pass-status {

    .form-wrapper {
      padding-left: 0;
      list-style: none;
      margin: 0;

      li {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e8e8e8;

        &:last-of-type,
        &:nth-last-child(2) {
          border-bottom: unset;
        }

        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        p {
          margin: 0;

          &.title {
            width: 90%;
            display: inline-block;

            + button {
              width: 10%;
              vertical-align: top;
              text-align: right;
              cursor: pointer;
              display: inline-block;
              padding: 0;

              &:focus {
                outline: none;
              }

              i.fa-question-circle {
                position: relative;
                font-size: 20px;
                left: unset;
                top: unset;
                right: unset;
                color: $gestorando;
              }
            }
          }
        }

        div {
          &.input-wrapper {
            input {
              background-color: $white;
            }
          }

          &.radio-wrapper {
            label {
              display: block;

              input {
                margin-right: 10px;
              }
            }
          }
        }

        .no-obra-social,
        .tiene-obra-social,
        .medicina-prepaga,
        .select-obra-social,
        .cuit-conyuge {
          display: none;
          position: relative;

          &.selected {
            display: block;
          }

          .select {
            &.disabled {
              opacity: 0.6;
              pointer-events: none;

              > div {
                cursor: not-allowed;
              }
            }
          }

          .show-info-obra-social-button {
            color: $gestorando;
            padding: 0;
          }

          .obra-social-info {
            display: none;

            span {
              font-family: "Avenir-Heavy";
            }
          }

          ~ .modal {
            &:before {
              content: "";
              width: 100vw;
              height: 100vh;
              background-color: $black;
              opacity: 0.5;
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }
        }

        .cuit-conyuge {
          height: 100%;
        }

        &:last-of-type {
          list-style: none;

          button {
            &[disabled] {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .ant-card {
      margin: 0 auto;
      // max-width: 720px;
      margin-bottom: 20px;

      li {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .main-icon {
    text-align: center;
    display: block;
    font-size: 65px;
    margin: auto auto 20px;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    @include transition(all, 0.2s, ease-in);

    &.fa-check-circle {
      color: $success;
      animation-name: animationSuccess;

      @keyframes animationSuccess {
        100% {
          @include transform(rotateY(720deg));
        }
      }
    }

    &.fa-exclamation-triangle {
      color: $warning;
      animation-name: animationWarning;

      @keyframes animationWarning {
        0% {
          @include transform(translateY(100px));
          opacity: 0;
        }

        100% {
          @include transform(translateY(0));
          opacity: 1;
        }
      }
    }
  }

  .btn-next {
    position: relative;
  }
  .confirm-button {
    @include btn;
    position: relative;
    box-shadow: inset 0px 1px 0px 0px #54a3f7;
    background-color: #007dc1;
    border-radius: 26px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    padding: 0px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #154682;

    &:hover {
      background-color: #0061a7;
    }

    &:active {
      position: relative;
      top: 1px;
    }
  }
}
.alert-plans-tramites button {
  @extend .btn-next;
  span {
    color: $dark-blue;
  }
}
.alert-plans-title {
  font-size: 1rem;
  button {
    color: #0061a7;
  }
}

.footer-facturas-text {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #40a9ff;
}
.need-inscription-button {
  background-color: #2670b3 !important;
  border-color: #2670b3 !important;
  width: 182.9px;
}
.modal-to-show-images {
  padding: 0 !important;
}
.need-inscription-banner {
  margin-top: 15px;
}

@media only screen and(max-width:767px) {
  div.alert-plans-tramites {
    display: flex;
    flex-direction: column;
  }
  div.alert-plans-tramites > button {
    position: initial;
    margin-top: 7px;
  }
  div.alert-plans-tramites > span {
    text-align: center;
  }
}
