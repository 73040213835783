.select-wrapper {
  @include select;

  // Custom select styles used with react-select library
  .custom-select-styles {
    &.valid {
      .css-yk16xz-control,
      .css-2o5izw {
        border: 2px solid $success;
      }
    }

    &.invalid {
      .css-yk16xz-control,
      .css-2o5izw {
        border: 2px solid $error;
      }
    }

    > div {
      > div {
        &:nth-child(3) {
          font-size: 14px;
        }
      }

      .css-1pahdxg-control {
        height: 45px;
        box-shadow: none;
        border: 2px solid $gestorando;
      }

      .css-yk16xz-control {
        cursor: pointer;
        height: 45px;
        background: $white;
        border: 2px solid #c4cdd5;
      }

      .css-1wa3eu0-placeholder {
        color: #ccc;
        opacity: 0.5 !important;
      }
    }
  }

  // Custom select styles used with antd library
  .custom-select-styles--antd {
    .ant-select {
      width: 100%;
      font-size: inherit;

      &.valid,
      &.ant-select-focused {
        .ant-select-selection {
          border: 2px solid $success !important;
        }
      }

      &-selection {
        border-radius: 5px;
        border: 1px solid $gray !important;
        height: 43px;
        @include transition(all, 0.2s, ease-in);

        &.ant-select-open {
          border: 2px solid $success !important;
          -webkit-box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.25);
          box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.25);
        }

        &:hover {
          border: 2px solid $success !important;
        }

        &__rendered {
          line-height: 43px;
          height: 100%;
        }

        .ant-select-arrow {
          right: 40px;
          top: 0;
          margin-top: 0;
          @include transition(all, 0.2s, ease-in);
        }
      }
    }
  }
}
