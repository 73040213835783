/*
*
IOS guidelines visual design colors: https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/
*
*/
$body: #212b36;
$gestorando: #5334ed;
$dark-blue: #190a7b;
$pale-grey-two: #f1f6ff;
$black: #000;
$white: #fff;
$success: #5cb85c;
$cyan: #61f5e5;
$light-cyan: #a2fff5;
$red: #ff0e00;
$error: #fc6158;
$warning: #ffcc00;
$orange: #ff590a;
$gray: #c4cdd5;
$btn-back-gray: #efefef;
$icon-color: #95a7b7;
$disabled: #a1a5a8;
$antd-blue: #40a9ff;
$carolina-blue: #89b3ff;
.text-gestorando {
  color: $gestorando !important;
}

.text-danger {
  color: $error !important;
}

.text-success {
  color: $success !important;
}

.bold {
  font-family: "Avenir-Heavy" !important;
}

.link {
  color: $gestorando;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.hoverable {
  @include transition(all, 0.3s, ease-in);

  &:hover {
    color: $antd-blue;
    cursor: pointer;
  }
}

.hide-on-desktop {
  @include min-bp-big-tablet {
    display: none !important;
  }
}

.hide-on-big-tablet {
  @include bp-big-tablet {
    display: none !important;
  }
}

.hide-on-tablet {
  @include bp-tablet {
    display: none !important;
  }
}

.hide-on-mobile {
  @include bp-mobile {
    display: none !important;
  }
}

.show-on-desktop {
  @include min-bp-big-tablet {
    display: block !important;
  }
}

.show-on-big-tablet {
  @include bp-big-tablet {
    display: block !important;
  }
}

.show-on-tablet {
  @include bp-tablet {
    display: block !important;
  }
}

.show-on-mobile {
  @include bp-mobile {
    display: block !important;
  }
}
