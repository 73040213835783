.card {
  @include bp-small-mobile {
    border-top: 0;
    border-radius: 0;
  }

  .card-header {
    background: none;
    border: none;
    border-radius: unset;
    text-align: center;
    padding-bottom: 0;
  }
}
