html, #app, body {
  background-color: #f2f2f2;
}

body {
  font-weight: normal;
  text-rendering: optimizeLegibility !important;
  font-size: 1rem;
  color: $body;
  -ms-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
  -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.

  #app  {
    > .content {
      padding-top: 30px;

      @include bp-small-mobile {
        padding-top: 0;
      }
    }
  }
}

@include inputPlaceholder(#ccc);

a {
  color: $gestorando;
  text-decoration: none;

  &:hover {
    color: $gestorando;
    text-decoration: underline;
  }
}

button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
}

b, strong {
  font-family: 'Avenir-Heavy';
  font-weight: unset;
}

.modal-dialog {
  transform: translateY(10%);
}

.modal {
  &:before {
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: $black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
