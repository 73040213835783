.forgot-password {
  max-width: 475px;
  padding: 0;
  margin: 30px auto 0;
  text-align: center;
  @include transition(all, 0.2s, ease-in);

  @include bp-small-mobile {
    margin-top: 0;
    padding-bottom: 63px;
    max-width: initial;
    width: initial;
    box-shadow: unset;
    border: none;
  }

  .card {

    @include bp-small-mobile {
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.3);
      border: none;
    }

    &-body {

      .card-title {
        margin-bottom: 10px;
        text-align: center;

        @include bp-small-mobile {
          font-size: 20px;
        }

        + hr {
          margin-top: 0;
        }
      }

      > div {
        > .input-wrapper {
          > div {
            max-width: 100%;
            display: block;
            margin: 0 auto 20px;
          }
        }

        input {
          padding-left: 35px;
        }
      }

      p {
        margin: 0;
      }

      .btns-next-back-wrapper {
        position: relative;
        margin-top: 50px;
        left: initial;
        right: initial;
        bottom: initial;

        @include bp-small-mobile {
          position: fixed;
          margin-top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
