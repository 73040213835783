&.container {
  .billing {
    @import "billing";
  }
  .payments-paid-out {
    max-width: 997px;
    margin: 0 auto 0;
    display: flex;

    @include bp-mobile {
      p {
        text-align: center;
      }
    }
  }
  .payments-pending {
    max-width: 997px;
    margin: 24px auto 24px auto;
    display: flex;
    button {
      margin-left: 12px;
    }
    p {
      margin: auto 0;
    }
    @include bp-mobile {
      display: block;
      p {
        text-align: center;
      }
      button:first-child {
        margin-left: 0px;
      }
      &--footer {
        display: flex;
        justify-content: center;
      }
    }
  }
  .tramites-details {
    background-color: $pale-grey-two;
    padding-top: 26px;
    padding-bottom: 42px;
    max-width: 997px;
    margin: 12px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    position: relative;

    &--detail {
      &-title {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: $carolina-blue;
        text-align: center;
        line-height: 25px;
        letter-spacing: 0.23px;
        margin-top: initial;
        margin-right: initial;
        margin-left: initial;
        margin-bottom: 4px;
      }
      &-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        grid-area: details;

        ul {
          margin: 0 auto;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            height: auto;
            opacity: 1;

            svg,
            p {
              display: inline-block;
              vertical-align: top;
            }

            svg {
              margin-top: 6px;
              width: 20.3px;
            }
          }
        }
      }
    }
    &--top-triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 12px;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      top: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    &--left-pattern {
      display: grid;
      grid-row-gap: 11px;
      position: absolute;
      left: -10px;
      top: 12px;
      bottom: 0;
      height: 90%;

      @include bp-mobile {
        display: none;
      }
    }
    &--right-pattern {
      display: grid;
      grid-row-gap: 11px;
      position: absolute;
      right: -10px;
      top: 12px;
      bottom: 0;
      height: 90%;

      @include bp-mobile {
        top: unset;
        grid-row-gap: 0;
        grid-column-gap: 11px;
        right: 0;
        left: 0;
        bottom: -10px;
        height: initial;
        justify-content: space-around;
        width: 90%;
        margin: 0 auto;
        grid-template-columns: auto auto auto auto auto auto auto auto auto;
      }
    }
    &--pattern-circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: $white;
    }
    &--price {
      &-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        grid-area: price;

        @include bp-mobile {
          a {
            display: none;
          }
        }
      }
      &-title {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: $carolina-blue;
        text-align: center;
        line-height: 25px;
        letter-spacing: 0.23px;
        margin: initial;
      }
      &-ammount {
        font-size: 51px;
        font-weight: 900;
        font-style: normal;
        color: $gestorando;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.85px;
        margin-top: initial;
        margin-right: initial;
        margin-left: initial;
        margin-bottom: 31px;
        & span {
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          color: $gestorando;
          text-align: center;
          line-height: 26px;
          letter-spacing: 0.41px;
          margin: initial;
        }
      }
    }
    &--button-wrapper {
      grid-area: button;
      display: none;

      @include bp-mobile {
        margin-top: 29px;
        display: block;
      }
    }
    @include bp-mobile {
      padding-top: 25px;
      padding-bottom: 33px;
      padding-left: 25px;
      padding-right: 25px;
      display: grid;
      grid-template-columns: auto;
      justify-content: center;
      grid-template-areas:
        "price"
        "details"
        "button";
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .divider-desktop {
    width: 183px;
    height: 1px;
    background-color: $carolina-blue;
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(90deg);

    @include bp-mobile {
      display: none;
    }
  }
  .divider-mobile {
    display: none;
    width: 100%;
    height: 1px;
    background-color: $carolina-blue;

    @include bp-mobile {
      display: block;
      margin: 10px auto 15px;
    }
  }
  .pagado-text {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    &.desktop {
      @include bp-mobile {
        display: none;
      }
    }
  }
  .get-product-button {
    background-color: $cyan;
    box-shadow: unset;
    text-align: center;
    max-width: 300px;
    cursor: pointer;
    height: 45px;
    margin-top: initial;
    margin-bottom: initial;
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    padding: initial;
    transition: all 0.17s linear 0s;
    color: $gestorando;
    font-size: 16px;
    font-weight: 900;
    &.desktop {
      @include bp-mobile {
        display: none;
      }
    }
  }
}
