.payment-method {
  padding-bottom: 15px;

  @include bp-small-mobile {
    margin: auto -15px;
    padding-bottom: 63px;
  }

  > .card {
    .card-title {
      margin: 0;
      display: block;
      width: 100%;
      text-align: center;

      &.d-md-none {
        padding-top: 0.75rem;
      }

      h5 {
        margin: 0;
        display: inline-block;
      }

      img {
        display: inline-block;
        margin-left: 10px;
        width: 130px;
        vertical-align: middle;

        @include bp-mobile {
          width: 88px;
        }
      }
    }

    .card-header {
      display: none;

      @include bp-mobile {
        display: block;
      }

      p {
        text-align: left;

        &.price {
          color: $gestorando;
          margin: 1rem 0 0;

          span {
            font-size: 20px;
          }
        }
      }

      ul {
        padding: 0;
        margin: auto;
        text-align: left;
        max-width: 290px;
      }
    }

    .card-body {
      padding-top: 0;

      .select-una-opcion-text {
        margin: 20px 0 0;
        position: relative;

        @include bp-mobile {
          margin: 0 0 20px;
        }

        &::before {
          content: "";
          background-color: rgba(0, 0, 0, 0.1);
          height: 1px;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
        }

        h5 {
          background-color: $white;
          width: 250px;
          position: absolute;
          left: 0;
          right: 0;
          top: -10px;
          margin: auto;
          padding: 0 15px;
        }
      }
      .button-title-header {
        display: flex;
        justify-content: center;
        & h5 {
          margin: 15px 0 0;
        }
        @include bp-mobile {
          display: none;
        }
      }
      .go-back-payment-button {
        background-color: rgba(0, 0, 0, 0);
        width: 50px;
        height: 50px;
        border-radius: 50px;
        left: 20px;
        position: absolute;
        & :hover {
          background-color: rgba(0, 0, 0, 0.1);
          width: 35px;
          height: 35px;
          border-radius: 50px;
          font-size: 1rem;
        }
        & i {
          font-size: 1.5rem;
          color: #2670b3;
        }
      }
      .btns-payment-wrapper {
        margin-top: 20px;

        @include bp-mobile {
          margin-top: 0;
        }
      }

      .btns-payment-wrapper,
      .btns-other-payment-wrapper {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        position: relative;

        > div {
          display: inline-block;

          &:nth-child(1) {
            width: 55%;
            margin-right: 3%;

            @include bp-tablet {
              width: 100% !important;
              margin-bottom: 15px;
              display: block;
            }

            @include bp-small-mobile {
              margin-right: 0;
            }
          }

          &:last-of-type {
            width: 42%;

            @include bp-tablet {
              width: 100% !important;
              display: block;
            }

            @include bp-small-mobile {
              margin-left: 0;
            }
          }
        }

        .btn {
          width: 100%;
          height: 65px;
          background-color: rgba(225, 225, 226, 0.6);
          border-radius: 5px;
          text-align: center;
          padding: 0;
          position: relative;
          @include transition(all, 0.2s, ease-out);

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:hover {
            background-color: #eff7fc;
            border: 1px solid #319fda;

            @include bp-mobile {
              background-color: rgba(225, 225, 226, 0.6);
            }
          }

          &.selected {
            background-color: #effcef;
            border: 2px solid $success;

            &:hover {
              border: 2px solid darken($success, 15%);
            }
          }

          p {
            width: 100%;
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
          }

          div.images {
            width: 100%;

            img {
              width: 45px;
              display: inline-block;
              vertical-align: middle;
              margin: 0 2px;

              &.amex {
                width: 33px;
              }

              @include bp-mobile {
                &.cmr,
                &.cordial {
                  display: none;
                }
              }

              @include bp-small-mobile {
                &.cordobesa {
                  display: none;
                }
              }
            }
          }
        }
      }

      .btns-other-payment-wrapper {
        margin-top: 16px;

        > div {
          width: calc(50% - 1.5%);
        }
        .button-othet-payments {
          width: 100% !important;
        }
      }

      .debit-card-wrapper,
      .credit-card-wrapper {
        text-align: center;
        width: 100%;
        display: none;

        > h5 {
          color: $error;
          text-align: left;
          margin: 25px 0 0;
        }

        form {
          padding-top: 40px;
          height: 100%;

          > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: left;

            li {
              display: inline-block;
              position: relative;
              margin-bottom: 50px;
              vertical-align: unset;
              @include transition(all, 0.2s, ease-in);

              @include bp-small-mobile {
                margin-bottom: 40px;
              }

              &:nth-child(1) {
                width: 37%;

                @media only screen and (min-width: 992px) and (max-width: 1199px) {
                  width: 29%;
                }

                @media only screen and (max-width: 991px) {
                  width: 45%;
                }

                @media only screen and (max-width: 499px) {
                  width: 100%;
                }
              }

              &:nth-child(2),
              &:nth-child(6),
              &:nth-child(9) {
                @media only screen and (min-width: 500px) {
                  margin: 0 2%;
                }
              }

              &:nth-child(2),
              &:nth-child(3) {
                width: 15%;

                @media only screen and (min-width: 992px) and (max-width: 1199px) {
                  width: 19%;
                }

                @media only screen and (max-width: 991px) {
                  width: 25%;
                }

                @media only screen and (max-width: 499px) {
                  width: 49%;
                }
              }

              &:nth-child(3) {
                margin-right: 2%;

                @media only screen and (max-width: 991px) {
                  margin-right: 0;
                }

                @media only screen and (max-width: 499px) {
                  margin-left: 2%;
                }
              }

              &:nth-child(4) {
                width: 24%;

                @media only screen and (max-width: 991px) {
                  width: 49%;
                }

                @media only screen and (max-width: 499px) {
                  width: 100%;
                }

                i {
                  position: absolute;
                  right: -23px;
                  top: 9px;
                  font-size: 20px;
                  color: $gestorando;
                  cursor: pointer;
                  text-align: left;

                  @media only screen and (max-width: 991px) {
                    left: 125px;
                    right: 0;
                    top: -23px;
                    margin-left: 5px;
                  }
                }

                .fa-question-circle {
                  &:hover {
                    + .question-security {
                      display: block;
                    }
                  }
                }

                .question-security {
                  text-align: left;
                  border-radius: 3px;
                  transform: translate(5px, 5px);
                  width: 400px;
                  height: 132px;
                  display: none;
                  border: 10px solid rgba(0, 0, 0, 0.7);
                  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
                  box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
                  background-color: $white;
                  position: absolute;
                  z-index: 4;

                  @media only screen and (max-width: 1199px) {
                    transform: translate(-29px, 8px);
                  }

                  @media only screen and (max-width: 991px) {
                    transform: translate(0, -23px);
                  }

                  @media only screen and (max-width: 499px) {
                    left: 0;
                    max-width: 500px;
                    width: 100%;
                    height: inherit;
                  }

                  .triangle {
                    border-bottom: 10px solid rgba(0, 0, 0, 0.7);
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    position: absolute;
                    width: 0;
                    left: 0;
                    right: 0;
                    top: -20px;
                    margin: 0 auto;

                    @media only screen and (max-width: 991px) {
                      left: 114px;
                      right: initial;
                    }
                  }

                  .close {
                    position: absolute;
                    opacity: 1;
                    background-color: #319fda;
                    border-left: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    border-top: none;
                    border-right: none;
                    color: #fff;
                    cursor: pointer;
                    font-family: arial, sans-serif;
                    font-weight: 700;
                    text-align: center;
                    -moz-border-radius-topright: 3px;
                    -webkit-border-top-right-radius: 3px;
                    -moz-border-radius-bottomright: 0;
                    -webkit-border-bottom-right-radius: 0;
                    -moz-border-radius-topleft: 0;
                    -webkit-border-top-left-radius: 0;
                    -moz-border-radius-bottomleft: 0;
                    -webkit-border-bottom-left-radius: 0;
                    box-shadow: none;
                    height: 25px;
                    width: 25px;
                    right: -10px;
                    top: -10px;
                    font-size: 20px;
                    line-height: 1;
                    border-radius: 0 3px 0 0;
                  }

                  img {
                    padding: 4px;

                    @media only screen and (max-width: 499px) {
                      width: 100%;
                    }
                  }

                  span {
                    font-size: 11px;
                    width: 50%;
                    position: absolute;
                    top: 35%;

                    @media only screen and (max-width: 499px) {
                      position: relative;
                      display: block;
                      margin-bottom: 10px;
                      text-align: center;
                      width: 100%;
                    }
                  }
                }
              }

              &:nth-child(n + 5) {
                @media only screen and (min-width: 992px) {
                  width: 32%;
                }
              }

              &:nth-child(5),
              &:nth-child(10) {
                @media only screen and (max-width: 991px) {
                  width: 49%;
                  margin-left: 2%;
                }

                @media only screen and (max-width: 499px) {
                  width: 100%;
                  margin-left: 0;
                }
              }

              &:nth-child(6) {
                @media only screen and (max-width: 991px) {
                  width: 25%;
                  margin-left: 0;
                }

                @media only screen and (max-width: 499px) {
                  width: 49%;
                }
              }

              &:nth-child(7) {
                @media only screen and (max-width: 991px) {
                  width: 35%;
                  margin-left: 0;
                  margin-bottom: 0;
                }

                @media only screen and (max-width: 499px) {
                  width: 49%;
                  margin-left: 2%;
                }
              }

              &:nth-child(8) {
                margin-bottom: 0;
                margin-right: 2%;

                @media only screen and (max-width: 991px) {
                  width: 35%;
                  margin-left: 2%;
                  margin-right: 0;
                }

                @media only screen and (max-width: 499px) {
                  width: 100%;
                  margin-left: 0;
                }
              }

              &:nth-child(9) {
                margin-bottom: 0;
                margin-left: 0;

                @media only screen and (max-width: 991px) {
                  width: 49%;
                  margin: 0;
                  margin-top: 40px;
                }

                @media only screen and (max-width: 499px) {
                  width: 49%;
                }
              }

              &:nth-child(10) {
                margin-bottom: 0;

                @media only screen and (max-width: 991px) {
                  margin-top: 40px;
                }

                @media only screen and (max-width: 499px) {
                  width: 49%;
                  margin-left: 2%;
                }
              }

              > p {
                margin: 0;
                text-align: left;
                font-size: 12px;
                position: absolute;
                top: -20px;
                @include transition(all, 0.2s, ease-in);

                @include bp-tablet {
                  width: 150px;
                }

                @include bp-mobile {
                  font-size: 13px;
                }
              }

              label {
                cursor: pointer;
                margin: 0;
                width: 100%;

                @include inputPlaceholder(#ccc);

                input {
                  height: 38px;
                  line-height: normal;
                  @include transition(all, 0.2s, ease-in);

                  &.error-border {
                    border-color: $error;

                    &:focus {
                      border-color: $error;
                    }
                  }

                  &:focus {
                    outline: none;
                    border-color: $gestorando;
                    box-shadow: none;
                  }
                }

                select {
                  @include transition(all, 0.2s, ease-in);

                  &.error-border {
                    border-color: $error;

                    &:focus {
                      border-color: $error;
                    }
                  }

                  &:focus {
                    outline: none;
                    border-color: $gestorando;
                    box-shadow: none;
                  }
                }

                .active-border {
                  border-color: $gestorando;
                }

                .error-message {
                  color: $error;
                  text-align: left;
                  font-size: 12px;
                  position: absolute;
                }
              }
            }
          }

          .btn-next {
            margin-top: 30px;
            position: relative;

            @include bp-mobile {
              transform: none;
            }
          }
        }
      }

      .other-payments-wrapper {
        display: none;
        padding: 1rem 30px 0;
        position: relative;

        @include bp-mobile {
          padding: 0;
        }

        .other-payments-text {
          margin: 0;

          @include bp-mobile {
            margin-top: 1rem;
          }
        }

        .cash-wrapper {
          padding-top: 2rem;
          padding-left: 0;
          margin: 0;
          list-style: none;
          display: none;

          li {
            position: relative;

            button {
              cursor: pointer;
              width: calc(50% - 1.5%);
              height: 130px;
              border-radius: 10px;
              border: 1px solid #f8f8f8;
              box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.5);
              text-align: center;
              margin: 0;
              position: relative;

              &:focus {
                outline: none;
              }

              &:hover {
                box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.7);
              }

              @include bp-big-tablet {
                height: 115px;
              }

              @include bp-tablet {
                margin-bottom: 3%;
                width: 47%;
              }

              @include bp-mobile {
                height: 27vw;
                width: 44%;
                margin: 0 3% 6%;
              }

              img {
                position: absolute;
                margin: 0 auto;
                left: 0;
                right: 0;
              }

              &:first-child {
                margin-right: 3%;

                img {
                  top: 15%;
                  width: 25%;

                  @include bp-tablet {
                    width: 45%;
                  }

                  @include bp-mobile {
                    width: 15vw;
                    top: 6vw;
                  }
                }
              }

              &:last-child {
                img {
                  top: 33%;
                  width: 45%;

                  @include bp-tablet {
                    top: 34%;
                    width: 75%;
                  }

                  @include bp-mobile {
                    top: 40%;
                  }
                }
              }
            }
          }
        }
      }

      .secure-services-text {
        float: left;
        width: 100%;
        margin-top: 20px;

        hr {
          margin-top: 0;
        }

        .secure-icon {
          margin-right: 2%;
          vertical-align: middle;
          float: left;

          i {
            font-size: 32px;
            color: #06d1d2;
          }
        }

        .secure-text {
          p {
            margin: 0;
            padding-left: 45px;
            font-size: 13px;
            color: $disabled;

            a {
              text-decoration: underline;
              color: $gestorando;
            }
          }
        }
      }
    }
  }
}

.service-description {
  > .card {
    @include bp-mobile {
      border: none;
      box-shadow: none;
    }

    .card-header {
      padding-bottom: 12px;

      @include bp-mobile {
        padding-top: 0;
      }

      .card-title {
        margin: 0;
        text-align: center;
        color: $gestorando;
        font-family: "Avenir-Roman";
      }
    }
    .button-title-header-mobile {
      display: block;
      & button {
        display: none;
      }

      @include bp-mobile {
        display: flex;
        & button {
          display: block;
          position: absolute;
          left: 0;
          color: #2670b3;
          & .anticon {
            vertical-align: 0.2em;
          }
        }
      }
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;

      @include bp-mobile {
        padding-left: 0;
        padding-right: 0;
      }

      ul {
        padding: 0;
        margin: 0;

        @include bp-mobile {
          margin: 0 auto;
        }

        li {
          font-size: 14px;
          margin: 5px 0 5px 15px;

          @include bp-big-tablet {
            font-size: 1rem;
          }

          &.price {
            list-style: none;
            color: $gestorando;
            margin: 0;
            font-size: 1rem !important;

            @include bp-mobile {
              margin: 10px 0 3px;
            }

            p {
              margin: 16px 0;
              font-family: "Avenir-Roman";
              text-align: left;

              span {
                font-size: 20px;
                line-height: 1;
                float: right;
                margin-top: 4px;

                @include bp-big-tablet {
                  font-size: 18px;
                }
              }

              &.detalle {
                span {
                  font-size: 18px;

                  @include bp-big-tablet {
                    font-size: 18px;
                  }
                }
              }

              &.total {
                font-size: 18px;
                margin-bottom: 0.5rem;
                color: #222;
                text-align: right;

                span {
                  font-size: 23px;
                  margin-left: 10px;

                  @include bp-big-tablet {
                    font-size: 21px;
                  }
                }
              }
            }
          }

          &.tax {
            list-style: none;
            text-align: center;
            font-size: 11px;
            margin: 0 0 1rem 0;

            @include bp-big-tablet {
              font-size: 12px;
            }
          }
        }
      }

      .mp-logo {
        max-width: 130px;
        margin: 0 auto;
        display: block;
      }

      .details-info {
        font-size: 12px;
        text-align: center;
        color: $disabled;
        margin-top: 1rem;

        span {
          color: $gestorando;
        }
      }
    }
  }
}

.ticket-generated {
  padding-bottom: 15px;

  > .card {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    @include bp-mobile {
      border: none;
      background-color: #fff;
      border-top: 3px solid #2670b3;
      border-radius: 7px;
    }

    @include bp-small-mobile {
      max-width: 100%;
      border-top: 0;
      border-radius: 0;
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .first-block {
    width: 100%;
    margin: 0 auto;
    -webkit-box-shadow: 0 3px 5px #ccc;
    box-shadow: 0 3px 5px #ccc;

    @include bp-small-mobile {
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    div {
      &:first-child {
        background-color: #fbf8e3;
        border-radius: 7px;
        padding: 10px;

        i {
          color: #efc701;
          border: 2px solid #efc701;
          border-radius: 100%;
          padding: 9px 9px 0 11px;
          font-size: 28px;
          text-align: center;
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
        }

        p {
          font-size: 20px;
          font-weight: 300;
          line-height: 1.3;
          text-align: center;
          color: #666;
          margin: 10px 0 0;

          @media only screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      &:last-child {
        background-color: $white;
        padding: 10px;

        p {
          text-align: center;
          color: #666;
          margin-bottom: 15px;
        }

        div {
          text-align: center;
          padding: 0;

          span {
            background-color: #f7f7f7;
            border: 1px solid darken(#f7f7f7, 15%);
            padding: 3px 14px;
            border-radius: 6px;
            margin: 0 5px;
            display: inline-block;
            line-height: 42px;
            font-size: 20px;
          }
        }

        a {
          color: $white;
          background-color: $success;
          padding: 5px 15px;
          border-radius: 7px;
          display: block;
          text-align: center;
          width: 215px;
          margin: 15px auto 0;
          font-size: 18px;
        }
      }
    }
  }

  .last-block {
    width: 100%;
    margin: 0 auto 20px;

    div {
      &:first-of-type {
        background-color: $white;
        padding: 0 10px 10px;

        p {
          text-align: center;
          color: #666;
          margin-bottom: 10px;

          &:last-child {
            margin: 0;
          }
        }
      }

      &:last-child {
        background-color: #f4f4f4;
        padding: 10px;
        font-size: 15px;
        color: #b29054;
        text-align: center;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        @media only screen and (max-width: 767px) {
          font-size: 13px;
        }
      }
    }
  }

  .btns-next-back-wrapper {
    .btn-next {
      background-color: $cyan;
      padding-top: 0;
      padding-bottom: 0;
      width: calc(100% - 100px);
    }

    @include min-bp-small-mobile {
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 10px;
      margin-bottom: 20px;
    }

    button {
      @include min-bp-small-mobile {
        position: relative;
      }
    }
  }
}
