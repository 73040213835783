.dashboard-private {
  .btn {
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(41, 41, 64, 0.3) !important;

    &:hover {
      box-shadow: 3px 3px 5px rgba(41, 41, 64, 0.3) !important;
    }
  }

  &-title {
    margin: 15px 0 0;
    display: inline-block;

    + button {
      height: 28px;
      border-radius: 4px;
      border: none;
      background-color: #6078ff;
      color: $white;
      overflow: hidden;
      font-size: 15px !important;
      font-family: "Avenir";
      padding: 3px 12px;
      text-transform: uppercase;
      cursor: pointer;
      vertical-align: text-bottom;
      margin-left: 15px;
      margin-right: 15px;
      @include transition(all, 0.2s, ease-out);

      &:hover {
        color: $white;
        background-color: rgba(96, 120, 255, 0.9);
      }

      + span {
        display: inline-block;
        font-size: 14px;
      }
    }
  }

  &-date-selection {
    margin: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 300px;
    height: 40px;
    box-shadow: 0 2px 8px rgba(41, 41, 64, 0.1);
    border-radius: 4px;
    border: none;
    background-color: $white;
    overflow: hidden;
    z-index: 5;
    font-size: 15px !important;
    font-family: "Avenir";
    padding: 12px;
    cursor: pointer;

    + div {
      float: right;
      position: absolute;
      top: 15px;
      right: 15px;
      height: 40px;
      border: none;
      background-color: transparent;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid #e6e6ff;
      font-size: 15px !important;
      font-family: "Avenir";
      padding: 12px;
      z-index: 6;
      cursor: pointer;
      @include transition(all, 0.2s, ease-out);

      &:hover {
        span {
          background: #6078ff;
        }
      }

      span {
        content: "";
        display: block;
        width: 22px;
        height: 2px;
        background: black;
        @include transition(all, 0.2s, ease-out);

        &:nth-last-child(2) {
          margin-top: 5px;
        }

        &:last-child {
          margin-top: 5px;
        }
      }
    }

    &:focus {
      outline: none;
    }

    @media only screen and (max-width: 510px) {
      position: relative;
      right: initial;
      top: initial;
      margin-top: 10px;
    }
  }

  .subscriptions {
    &-title {
      margin: 30px 0 0;
    }
  }

  .graph-box {
    width: calc(25% - 18.4px);
    display: inline-block;
    min-height: 120px;
    padding: 15px;
    margin: 0 10px 10px !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.07);
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1024px) {
      width: calc(33% - 13.1px);
    }

    @media only screen and (max-width: 910px) {
      width: calc(32% - 5px);
    }

    @media only screen and (max-width: 767px) {
      width: calc(50% - 12px);
      margin-top: 20px !important;
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
    }

    &:nth-child(-n + 4) {
      @media only screen and (min-width: 910px) {
        margin-top: 25px !important;
      }
    }

    &:nth-child(-n + 3) {
      @media only screen and (min-width: 768px) and (max-width: 909px) {
        margin-top: 20px !important;
      }
    }

    &:nth-child(3n + 1) {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 20px !important;
        margin-left: 0 !important;
      }
    }

    &:nth-child(3n + 3) {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 0 !important;
      }
    }

    &:nth-child(2n + 1) {
      @media only screen and (max-width: 767px) {
        margin-left: 0 !important;
      }

      @media only screen and (max-width: 500px) {
        margin-right: 0 !important;
      }
    }

    &:nth-child(2n + 2) {
      @media only screen and (max-width: 767px) {
        margin-right: 0 !important;
      }

      @media only screen and (max-width: 500px) {
        margin-left: 0 !important;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9) {
      @media only screen and (min-width: 1025px) {
        margin-left: 0 !important;
      }
    }

    &:nth-child(4),
    &:nth-child(8) {
      @media only screen and (min-width: 1025px) {
        margin-right: 0 !important;
      }
    }

    &:hover {
      .view-details {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    .view-details {
      position: absolute;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 0.625rem;
      color: rgba(184, 184, 217, 0.6);
      bottom: 0.625rem;
      right: 0.625rem;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0;
      z-index: 2;

      &:before {
        content: "ðŸ‘€";
        position: relative;
        top: 1px;
        font-family: "SSAir";
        font-size: 10px;
        margin-right: 5px;
      }
    }

    .title {
      margin: 0 0 10px;
      position: relative;
      z-index: 1;
      font-size: 17px;
      color: #7171a6;
      float: none;
      padding-bottom: 5px;
      border-bottom: 1px solid #efefef;
      text-transform: capitalize;

      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }

    .primary {
      color: #6078ff;
      font-size: 26px;
      font-weight: 700;
      position: absolute;
      bottom: 0;
      top: 50%;
      z-index: 1;
      margin: 0;

      @media only screen and (max-width: 1199px) {
        font-size: 22px;
        top: 50%;
      }

      @media only screen and (max-width: 991px) {
        font-size: 25px;
        top: 47%;
      }

      &--shorter {
        top: 43%;
        font-size: 17px;
        color: #7171a6;
        font-weight: 400;

        + .trend {
          top: 27%;

          @media only screen and (max-width: 1400px) {
            top: 32%;
          }

          .units {
            color: #6078ff;
            font-weight: 700;
            margin-right: 0;
            width: 75px;

            @media only screen and (max-width: 1400px) {
              font-size: 16px;
              width: 50px;
            }

            @media only screen and (max-width: 1200px) {
              display: none;
            }
          }

          .percent {
            @media only screen and (max-width: 1400px) {
              font-size: 16px;
            }
          }

          i.up {
            @media only screen and (max-width: 1400px) {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      &--bis {
        top: 73%;
        font-size: 17px;
        color: #7171a6;
        font-weight: 400;

        + .trend {
          top: 56%;

          @media only screen and (max-width: 1400px) {
            top: 62%;
          }

          .units {
            color: #6078ff;
            font-weight: 700;
            margin-right: 0;
            width: 75px;

            @media only screen and (max-width: 1400px) {
              font-size: 16px;
              width: 50px;
            }

            @media only screen and (max-width: 1200px) {
              display: none;
            }
          }

          .percent {
            @media only screen and (max-width: 1400px) {
              font-size: 16px;
            }
          }

          i.up {
            @media only screen and (max-width: 1400px) {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }

    .trend {
      position: absolute;
      z-index: 1;
      right: 15px;
      top: 40%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      @media only screen and (max-width: 1024px) {
        font-size: 38%;
      }

      .units {
        font-size: 21px;
        margin-right: 50px;
      }

      .percent {
        font-size: 21px;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        position: relative;
        top: 0;

        @media only screen and (max-width: 1024px) {
          font-size: 20px;
        }

        ~ i {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          padding-top: 5.5px;
          display: none;
          text-align: center;
          font-size: 14px;
          margin-top: 5px;
          margin-right: 10px;
        }

        &.green {
          color: #12c457;

          ~ i.green {
            color: #12c457;
            background-color: rgba(18, 196, 87, 0.1);
          }

          &.up {
            ~ i.up.green {
              display: block;
            }
          }

          &.down {
            ~ i.down.green {
              display: block;
            }
          }
        }

        &.red {
          color: #e84c85;

          ~ i.red {
            color: #e84c85;
            background-color: rgba(232, 76, 133, 0.1);
          }

          &.up {
            ~ i.up.red {
              display: block;
            }
          }

          &.down {
            ~ i.down.red {
              display: block;
            }
          }
        }
      }

      span {
        text-transform: uppercase;
        color: #b8b8d9;
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 2em;
        display: inline-block;
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0 0.5rem;
        display: none;
      }
    }

    a:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .subscription-table-wrapper {
    margin-top: 30px;
    margin-bottom: 40px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.07);
    overflow: auto;
    transform: translate3d(0, 0, 0);
    position: relative;

    .ant-card {
      border: unset;
      border-radius: unset;
      box-shadow: unset;

      &-loading {
        padding: 8px 15px !important;
      }

      > div {
        padding: 0;
      }
    }

    .subscriptions-table {
      width: max-content;
      height: 516px;
    }

    table {
      border-radius: 6px;
      table-layout: unset;
      -webkit-border-horizontal-spacing: 0;
      -webkit-border-vertical-spacing: 0;
      margin: 0;
      width: 100%;

      td,
      th {
        position: relative;
        // z-index: 2 !important;
      }

      thead,
      tfoot {
        background-color: $white;

        th {
          z-index: 2 !important;
          background-color: $white;

          &:first-of-type {
            z-index: 999 !important;
          }

          &:not([rowspan]) {
            font-size: 16px;
            color: #7171a6;
            font-weight: 600;
            border: unset !important;
            text-align: center;
            padding-left: 6px;
            padding-right: 6px;
            background-color: #fcfcff;
          }

          &[rowspan] {
            font-size: 16px;
            color: #33334f;
            background-color: #fcfcff;
            font-weight: 600;
            border: none !important;
            vertical-align: middle;
          }
        }
      }

      thead {
        th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          left: 0;

          &:not([rowspan]) {
            box-shadow: 0 5px 5px 0 rgba(51, 51, 79, 0.07);
          }
        }
      }

      tfoot {
        th {
          position: -webkit-sticky;
          position: sticky;
          bottom: 0;
          left: 0;

          &:not([rowspan]) {
            box-shadow: 0 -5px 5px 0 rgba(51, 51, 79, 0.07);
          }
        }
      }

      tbody {
        tr {
          &:last-child {
            color: red;
          }

          th {
            font-size: 14px;
            color: #33334f;
            font-weight: 600;
            text-align: left;
            padding: 5px 5px 5px 15px;
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: $white;

            &:last-of-type {
              left: 126px !important;
              //padding-left: 5px;
              text-align: center;
              box-shadow: 5px 0px 5px 0 rgba(51, 51, 79, 0.07);
            }
          }

          td {
            color: #6078ff;
            font-weight: 800;
            font-size: 15px;
            padding: 7px 5px 5px 5px;
            text-align: center !important;
            width: calc(77vw / 12);
            z-index: 1;

            &:not(:empty) {
              background-color: rgba(35, 115, 180, 0.4);
            }

            &:hover {
              color: $white;

              &:not(:empty) {
                background-color: rgba(113, 113, 166, 0.38);
                color: $white;
              }

              &::before {
                content: "";
                background-color: rgba(96, 120, 255, 0.1);
                height: 100%;
                right: 0;
                position: absolute;
                top: 0;
                width: 10000px;
                z-index: -1;
              }

              &::after {
                content: "";
                background-color: rgba(96, 120, 255, 0.1);
                height: 10000px;
                left: 0;
                position: absolute;
                bottom: 0;
                // transform: translateY(50%);
                width: 100%;
                z-index: -1;
              }
            }

            &:nth-child(2) {
              background-color: #fcfcff;
            }
          }
        }
      }
    }
  }

  .plans-table-wrapper {
    width: 100%;
    display: inline-block;
    float: left;
    background-color: $white;
    margin-right: 1%;
    box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.07);
    border: 1px solid #e6e6ff;
    border-radius: 6px;
    margin-bottom: 50px;
    overflow: auto;

    h3 {
      margin: 0;
      padding: 12px;
      border-bottom: 1px solid #e6e6ff;
      font-weight: 600;
    }

    table {
      border-radius: 6px;
      table-layout: unset;
      -webkit-border-horizontal-spacing: 0px;
      -webkit-border-vertical-spacing: 0px;
      margin: 0;

      thead {
        tr {
          background-color: #fcfcff;
          text-transform: uppercase;

          th {
            border-bottom: none !important;
            color: #7171a6;
            font-size: 12px;
            cursor: pointer;
            text-align: left;

            &:first-child {
              padding-left: 12px;
            }

            &:hover {
              color: #33334f;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            color: #33334f;
            text-align: left;
            font-size: 15px;

            &:first-child {
              padding-left: 12px;
            }

            &:last-child,
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4) {
              color: #12c457;
            }
          }
        }
      }
    }
  }
}

#crm-metrics {
  .ant-layout {
    background-color: unset;
  }

  .header {
    margin: 8px 0;

    p,
    button,
    .ant-tree {
      display: inline-block;
    }

    button {
      margin-right: 8px;
      width: 80px;
    }

    .ant-calendar-picker {
      float: right;

      @include bp-mobile {
        max-width: calc(100% - 88px);
      }
    }
  }

  @include bp-big-tablet {
    .box-wrapper {
      width: calc(100%);

      &:nth-last-child(-n + 5) {
        margin-bottom: 8px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(4n + 1) {
        margin-left: 0;
      }

      &:nth-last-child(-n + 4) {
        margin-bottom: 0;
      }
    }
  }

  @include bp-tablet {
    .box-wrapper {
      width: calc(100%);

      &:nth-last-child(-n + 5) {
        margin-bottom: 8px;
      }

      &:nth-last-child(-n + 4) {
        margin-bottom: 8px;
      }

      &:nth-child(4n) {
        margin-right: 5px;
      }

      &:nth-child(4n + 1) {
        margin-left: 5px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
    }
  }

  @include bp-mobile {
    .box-wrapper {
      width: calc(100%);

      &:nth-last-child(-n + 5) {
        margin-bottom: 8px;
      }

      &:nth-last-child(-n + 4) {
        margin-bottom: 8px;
      }

      &:nth-last-child(-n + 3) {
        margin-bottom: 8px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(4n + 1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 5px;
      }

      &:nth-child(3n + 1) {
        margin-left: 5px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      }

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }

  .box-wrapper {
    margin: 0 5px 8px 5px;
    display: inline-block;
    vertical-align: top;

    .box {
      border-radius: 6px !important;
      box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.07);

      .ant-card-head {
        padding: 0 15px;
        border-bottom: unset;

        &-title {
          color: #7171a6;
          padding-bottom: 0;
          font-size: 1rem;
          font-family: "Avenir-Heavy";

          i {
            position: absolute;
            right: 15px;
            top: 10px;
          }

          hr {
            margin: 0;
          }

          .title {
            display: block;
            margin: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: calc(100% - 20px);

            @include bp-mobile {
              display: none;
            }
          }

          .mobile-title {
            display: none;
            margin: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: calc(100% - 20px);

            @include bp-mobile {
              display: block;
            }
          }
        }
      }

      .ant-card-body {
        padding: 0 15px 8px;

        p {
          color: #7171a6;
          text-transform: capitalize;
          margin: 0;
          font-size: 1rem;
        }

        span {
          color: #6078ff;
          display: inline-block;
          margin-top: 8px;
          line-height: 1;
          font-family: "Avenir-Heavy";
          font-size: 25px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.ant-calendar-picker-container {
  .ant-calendar-range-with-ranges {
    @include bp-mobile {
      width: 100%;
      max-width: 320px;
    }

    .ant-calendar-range-part {
      @include bp-mobile {
        width: 100%;
        float: unset;
      }

      .ant-calendar-input-wrap {
        > div {
          @include bp-mobile {
            margin: 0;
          }
        }
      }
    }
  }
}

.metrics-popover {
  max-width: 320px !important;
  width: 100% !important;

  .ant-popover-title {
    font-family: "Avenir-Heavy";
    font-size: 18px;
  }

  .ant-popover-inner-content {
    color: #7171a6;

    strong {
      color: rgba(0, 0, 0, 0.85);
    }

    .subtitle {
      font-family: "Avenir-Heavy";
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    .ant-btn {
      color: $white;
    }
  }
}

// .error-boundary {
//     margin-top: 8px;

//     a {
//         color: $gestorando-blue;
//     }
// }
.overpass-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.modal-saldo-a-favor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.ant-select-selection__placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}
